import english from "./locales/english.json";
import hindi from "./locales/hindi.json";
import kannada from "./locales/kannada.json";
import marathi from "./locales/marathi.json";

const resources = {
    en: {
        translation: {
            ...english,
        },
    },
    hi : {
        translation: {
            ...hindi
        }
    },
    kn : {
        translation : {
            ...kannada
        }
    },
    mr : {
        translation : {
            ...marathi
        }
    }
};

export default resources;
