// top level imports
import { useState, useEffect } from "react";

// React-i18n
import { useTranslation } from "react-i18next";

// React-toast
import { toast } from "react-toastify";

// API calls
import { saveGstDetails, getGstDetails } from "../api/miscellaneous";

// Component definition
export default function GSTForm({ gst }) {
    // hooks
    const { t } = useTranslation();

    // state definition
    const [gstNum, setGstNum] = useState('');
    const [loading, setLoading] = useState(false);

    // ComponentDidMount
    useEffect(() => {
        fetchGstDetails();
    }, []);
    
    /** Handler functions - starts */

    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);

    const submitGst = async () => {
        showLoader();
        try {
            const response = await saveGstDetails({ gst: gstNum })
            if (response.status === 'success') {
                toast.success(response.message);
            }
        } catch (err) {
            // show error
        } finally {
            hideLoader();
        }
    }

    const fetchGstDetails = async () => {
        showLoader();

        try {
            const response = await getGstDetails();

            if (response.status === "success") {
                setGstNum(response.gst);
            }
        } catch {
            // maybe catch error
        } finally {
            hideLoader();
        }
    }

    /** Handler functions - ends */

    // Main renderer
    return (
        <div className="page_Wrapper edit_Page_Wrapper">
            <h5 className="mb-4">GST Details</h5>
            <div className="edit_input_container">
                <label className="edit_input_label">{'GST Number'}</label>
                <input
                    type="text"
                    className="input-field"
                    value={gstNum}
                    onChange={e => setGstNum(e.target.value)}
                    disabled={loading}
                />
            </div>
            <button
                type="button" className="submit-button mt-4"
                onClick={submitGst}
                disabled={loading}
            >
                <p>{t('save-details')}</p>&nbsp;
                {loading && (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                )}
            </button>
        </div>
    )
}