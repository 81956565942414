import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Images
import addIcon from "../../assets/vector/add_outline_blue.svg";
import AddressBox from "../../components/AddressBox/AddressBox";

// MUI
import { Checkbox, FormControlLabel } from "@mui/material";

//Components
import PriceDetailsBox from "../../components/PriceDetailsBox/PriceDetailsBox";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { toast } from "react-toastify";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSiteCtx from "../../hooks/useSiteCtx";
import { useTranslation } from "react-i18next";

// API calls
import { getGstDetails } from "../../api/miscellaneous";
import { checkProdServiceabilityStatus } from "../../api/Product";
import { getAddress } from "../../api/Address";
import { initOrder, paymentInit } from "../../api/OrdersApi";

// toast.configure();

// Component definition
const HomeDelivery = () => {
    const { setHeaderData } = useSiteCtx();
    const { setEditID } = useUserCtx();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const matches = width >= 768;

    // state definitions
    const [disable, setDisable] = useState(true);
    const [addressSelected, setAddressSelected] = useState("");
    const [initProcessing, setInitProcessing] = useState(false);
    const [gst, setGst] = useState('');
    const [claimGst, setClaimGst] = useState(false);
    const [serviceabilityCheckDone, setServiceabilityCheckDone] = useState(false);

    const {
        userAddress,
        setUserAddress,
        cartArray,
        setCartArray,
        userDefaultAddress,
        setDeliveryEstDays,
        orderInit,
        setOrderInit
    } = useUserCtx();

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Select Address",
            categoriesCond: false,
            header3Store: true,
            header3Cart: true,
            header3Profile: true,
        });
        fetchGstDetails();
    }, []);

    useEffect(() => {
        getAddress().then((res) => {
            if (res) {
                setUserAddress({
                    loaded: true,
                    no_of_address: res.no_of_address,
                    address: res.address,
                });
            }
        });
    }, []);

    const breadCrumbsData = [
        {
            text: t('home'),
            url: "/",
        },
        {
            text: t('profile.cart'),
            url: "/mycart",
        },
        {
            text: t('delivery.deliveryOption'),
            url: "",
        },
    ];

    useEffect(() => {
        setOrderInit((prev) => ({
            ...prev,
            shippingAddressId: addressSelected,
            claimGst
        }));
    }, [addressSelected, claimGst]);

    useEffect(() => {
        if (cartArray.no_of_carts > 0) {
            let productId = [];
            let quantity = [];
            cartArray.cart.forEach((item) => productId.push(item._id));
            cartArray.cart.forEach((item) => quantity.push(parseInt(item.quantity)));
            setOrderInit((prev) => ({
                ...prev,
                productId: productId,
                quantity: quantity,
            }));
        }
    }, [cartArray]);

    /* useEffect(() => {
        if (cartArray && cartArray.no_of_carts > 0 && userDefaultAddress.loaded) {
            let itemArray = cartArray.cart.map((item) => {
                let itemObj = {
                    ean: item.ean,
                    title: item.title,
                    quantity: item.quantity,

                };
                return itemObj;
            });

            const pincode = userDefaultAddress?.address?.zip;
            for (const item of itemArray) {
                // checkServiceAvailability(item, pincode);
            }
        }
    }, [cartArray, userDefaultAddress]); */

    /** Handler functions - starts */

    const checkServiceAvailability = async (item, pincode) => {
        const response = await checkProdServiceabilityStatus(item, pincode);
        const payload = response.data;
        if (payload.status === 'error' && !payload.data?.length) {
            setCartArray(prev => {
                return {
                    ...prev,
                    cart: prev.cart.filter(prod => prod.ean !== item.ean)
                };
            })
            toast.error(`Service unavailable for ${item.title}`)
        }
    }

    const handleOrderInit = async (e) => {
        e.preventDefault();
        setInitProcessing(true);
        
        const OrderinitRes = await initOrder(orderInit); //saves order in DB and returns the order
        if (!OrderinitRes) return setInitProcessing(false);

        let orderId = OrderinitRes._id; //returns order id

        const payInitRes = await paymentInit(orderId);
        // 1) Takes Order ID and checks its presence in Order Collection in DB.
        // 2) Creates Payment collection in DB and sends payment link.
        window.open(payInitRes, "_parent");
        setInitProcessing(false);
    };

    const fetchGstDetails = async () => {
        try {
            const response = await getGstDetails();
            if (response.status === "success") {
                setGst(response.gst)
            }
        } catch {
            // maybe catch error
        }
    }

    const handleClaimGstChange = (event) => {
        setClaimGst(event.target.checked);
    }

    const initServiceAvailabilityCheck = async () => {
        if (cartArray && cartArray.no_of_carts > 0 && userDefaultAddress.loaded) {
            let itemArray = cartArray.cart.map((item) => {
                let itemObj = {
                    ean: item.ean,
                    title: item.title,
                    quantity: item.quantity,

                };
                return itemObj;
            });

            const pincode = userDefaultAddress?.address?.zip;
            for (const item of itemArray) {
                await checkServiceAvailability(item, pincode);
            }
            setServiceabilityCheckDone(true)
        }
    }

    /** Handler functions - starts */
    
    return (
        <>
            <div className="page_Wrapper page_Margin_Top_Secondary">
                <BreadCrumbs data={breadCrumbsData} />
                <div className="desk_Page_Wrapper">
                    <aside className="side_Section" style={{ padding: "0" }}>
                        <PriceDetailsBox HideDetails={false} />
                    </aside>
                    <div className="order_Page_Right">
                        <p className="cart_Text section_Wrapper">{t('delivery.selectAddress')}</p>

                        {gst && (
                            <p className="cart_Text section_Wrapper">
                                <FormControlLabel
                                    label="Claim GST"
                                    control={<Checkbox onChange={handleClaimGstChange} />}
                                />
                            </p>
                        )}

                        <div className="home_Delivery_Options">
                            {userAddress.no_of_address !== 0
                                ? userAddress.no_of_address !== 0 &&
                                userAddress?.address?.map((address, index) => (
                                    <div className="home_Delivery_Option section_Wrapper" key={index}>
                                        <label
                                            htmlFor={address.id}
                                            className={`radiobtn-label home_Delivery_Label`}
                                            onClick={() => {
                                                setAddressSelected(address._id);
                                                setDisable(false);
                                            }}
                                        >
                                            <input type="radio" name="Delivery Address" id={address.id} value={address.id} />
                                            <span className="radio-custom"></span>
                                            <AddressBox
                                                setEditID={setEditID}
                                                address={address}
                                                deleteOption={false}
                                                defaultOption={false}
                                                border={false}
                                                fullWidth={true}
                                                classes={{
                                                    boxWrapperClass: "ms-1",
                                                }}
                                            />
                                        </label>
                                    </div>
                                ))
                                : ""}
                        </div>
                        <Link to={"/newaddress"} className="add_New_Address home_Delivery_New_Address section_Wrapper">
                            <img src={addIcon} alt="" />
                            <p>{t('address.new')}</p>
                        </Link>

                        {matches && (
                            <>
                                <div className="d-flex justify-content-between">
                                    <p>Check serviceability of products before making a purchase</p>
                                    <button
                                        className="btn btn-success mb-4 btn-block"
                                        disabled={!cartArray.loaded || !userDefaultAddress.loaded}
                                        onClick={initServiceAvailabilityCheck}
                                    >
                                        Check Service Availability
                                    </button>
                                </div>

                                <div className="home_Delivery_Submit">
                                    <button
                                        type="submit"
                                        className="submit-button"
                                        disabled={disable || initProcessing || !cartArray.cart.length || !serviceabilityCheckDone}
                                        onClick={handleOrderInit}
                                    >
                                        <p>{initProcessing ? t('delivery.processing') : t('delivery.continueForPayment')}</p>
                                    </button>
                            </div>
                            </>
                        )}

                        {!matches && (
                            <div className="address_Footer">
                                <button
                                    type="submit"
                                    className="submit-button"
                                    onClick={handleOrderInit}
                                    disabled={disable || initProcessing || !cartArray.cart.length || !serviceabilityCheckDone}
                                >
                                    <p>{initProcessing ? t('delivery.processing') : t('delivery.continueForPayment')}</p>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeDelivery;
