import { useEffect, useState } from "react";

export default function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.scrollY;
      let timeoutId;
  
      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }else{
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            setScrollDirection("up");
          }, 1000);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
  
    return scrollDirection;
  };
  
// import { useEffect, useState } from "react";

// export default function useScrollDirection() {
//   const [scrollDirection, setScrollDirection] = useState("up");

//   useEffect(() => {
//     let lastScrollY = window.pageYOffset;
//     let timeoutId;

//     const updateScrollDirection = () => {
//       const scrollY = window.pageYOffset;
//       const direction = scrollY > lastScrollY ? "down" : "up";

//       if (
//         direction !== scrollDirection &&
//         (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
//       ) {
//         setScrollDirection(direction);
//       }
//       else{
//         clearTimeout(timeoutId);
//         timeoutId = setTimeout(() => {
//           setScrollDirection("up");
//         }, 1000);
//       }

//       lastScrollY = scrollY > 0 ? scrollY : 0;
//     };

//     window.addEventListener("scroll", updateScrollDirection);

//     return () => {
//       window.removeEventListener("scroll", updateScrollDirection);
//     };
//   }, [scrollDirection]);

//   return scrollDirection;
// }
