import React, { useEffect } from "react";

//CSS
import "./EditAccount.css";

//Components
import EditDetails from "./EditDetails";
import useSiteCtx from "../../hooks/useSiteCtx";

// Component definitions
const EditAccont = () => {
    const { setModalDataMobile, setHeaderData } = useSiteCtx();

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Edit My Account Details",
            categoriesCond: false,
        });
    }, []);

    return (
        <>
            <EditDetails setModalDataMobile={setModalDataMobile} />
        </>
    );
};

export default EditAccont;
