import React from "react";
import "./RetailStorePage.css";
import StoreR from "../../assets/Store/StoreR.png";
import StoreL from "../../assets/Store/StoreL.png";

const RetailBanner = () => {
  return (
    <div className="retailBanner-component">
      <div className="retailBanner mobilescreen">
        <img src={StoreL} alt="store_mobile_banner_1" />
        <img src={StoreR} alt="store_mobile_banner_2" />
      </div>
      <div className="retailBanner desktop">
        <img src={StoreL} className="retainBanner1" alt="store_desktop_banner_1" />
        <img src={StoreR} className="retainBanner2" alt="store_desktop_banner_2" />
      </div>
    </div>
  );
};

export default RetailBanner;
