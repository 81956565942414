import React from "react";
import "./styles.css";
import EditIcon from "../../assets/png/editIcon.png";
import Image from "../../assets/png/changeTheNameImage.png";
import { useMediaQuery } from "@mui/material";
import useUserCtx from "../../hooks/useUserCtx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginAndSecurity = () => {
  const { userContext } = useUserCtx();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 500px)");
  const handleNavigation = (field, value) => {
    navigate("/edit-details", {
      state: {
        type: "edit-details",
        field,
        value
      },
    })
  }

  const breadCrumbsData = [
    {
      text: t('profile.profile') + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile"
    },
    {
      text: t('loginAndSecurity'),
      url: "/loginAndSecurity",
      isActive: location.pathname === "/loginAndSecurity"
    },
  ];

  return (
    <div className="loginSecWrapper">
      <div className="loginSecContent">
        <div className="loginSecLeft">
          <h1 className="loginSecPageName">{breadCrumbsData.map((item, index) =>
            <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
            </Link>)}</h1>
          {isMobile && (
            <div className="loginSecMobileImageContainer">
              <h1 className="loginSecTitle">Login & Security</h1>
              <img alt="loginSecImage" className="loginSecImage" src={Image} />
            </div>
          )}
          <div className="loginSecDetails">
            {!isMobile && <h1 className="loginSecTitle">Login & Security</h1>}
            <div className="loginSecDetailDiv">
              <div className="loginSecUserNameContainer">
                <h1 className="loginSecDetailName">Name: </h1>
                <h4 className="loginSecUserName">{userContext.fullName}</h4>
              </div>
              <div className="loginSecRightIcon">
                <img alt="editIcon" src={EditIcon} onClick={() => handleNavigation("fullName", userContext.fullName)} />
              </div>
            </div>
            <div className="loginSecDetailDiv">
              <div>
                <h1 className="loginSecDetailName">Mobile phone number:</h1>
                <p className="loginSecPara" style={{ marginBottom: "10px", fontSize: "1.3rem" }}>
                  {userContext.mobileNumber}
                </p>
                <p className="loginSecPara">
                  Swift sign-ins, effortless password recovery, and timely
                  security notifications.
                </p>
              </div>
              <div className="loginSecRightIcon">
                <img alt="editIcon" src={EditIcon} onClick={() => handleNavigation("mobileNumber", userContext.mobileNumber)} />
              </div>
            </div>
            <div className="loginSecDetailDiv">
              <div>
                <h1 className="loginSecDetailName">E-mail:</h1>
                <p className="loginSecPara" style={{ marginBottom: "10px", fontSize: "1.3rem" }}>
                  {userContext.email}
                </p>
                <p className="loginSecPara">
                  Enhance the security of your account by providing your email.
                  If we suspect an unusual login attempt, we can send an email
                  to confirm your identity and ensure the safety of your
                  account.
                </p>
              </div>
              <div className="loginSecRightIcon">
                <img alt="editIcon" src={EditIcon} onClick={() => handleNavigation("email", userContext.email)} />
              </div>
            </div>
            {/* <div className="loginSecDetailDiv">
              <div>
                <h1 className="loginSecDetailName">Password:</h1>
                <p className="loginSecPara">********</p>
              </div>
              <div className="loginSecRightIcon">
                <img alt="editIcon" src={EditIcon} />
              </div>
            </div> */}
          </div>
        </div>
        {!isMobile && (
          <div className="loginSecImageContainer">
            <img alt="loginSecImage" className="loginSecImage" src={Image} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginAndSecurity;
