import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PriceDetailsBox from "../../components/PriceDetailsBox/PriceDetailsBox";
import { getAddress } from "../../api/Address";
import { getProductServiceability } from "../../api/Product";
import { checkCoupon } from "../../api/couponApi";
import { toast } from "react-toastify";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSiteCtx from "../../hooks/useSiteCtx";
import { useTranslation } from "react-i18next";

// Component definitions
const DeliveryOptions = () => {
    const { setHeaderData } = useSiteCtx();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const matches = width >= 768;
    const { setUserAddress, cartArray, userDefaultAddress, setDeliveryEstDays, appliedCoupon, setAppliedCoupon, setOrderInit } = useUserCtx();
    const nav = useNavigate();

    // state definitions
    const [selected, setSelected] = useState(null);
    const [disable, setDisable] = useState(true);
    const [cartProdMoreThanOne, setCartProdMoreThanOne] = useState(false);
    const [couponInput, setCouponInput] = useState(appliedCoupon?.code);

    useEffect(() => {
        setOrderInit((prev) => ({
            ...prev,
            coupon: appliedCoupon ? appliedCoupon._id : "",
        }));
    }, [appliedCoupon]);

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Delivery option",
            categoriesCond: false,
            header3Store: true,
            header3Cart: true,
            header3Profile: true,
        });
    }, []);

    useEffect(() => {
        getAddress().then((res) => {
            // console.log(res);
            if (res) {
                setUserAddress({
                    loaded: true,
                    no_of_address: res.no_of_address,
                    address: res.address,
                });
            }
        });
    }, []);

    useEffect(() => {
        if (cartArray && cartArray.loaded && cartArray.no_of_carts === 1) {
            if (cartArray.cart[0]?.quantity === 1) {
                setCartProdMoreThanOne(false);
            } else {
                setCartProdMoreThanOne(true);
            }
        } else {
            setCartProdMoreThanOne(true);
        }
    }, []);

    useEffect(() => {
        if (cartArray.no_of_carts > 0) {
            let productId = [];
            let quantity = [];
            cartArray.cart.forEach((item) => productId.push(item._id));
            cartArray.cart.forEach((item) => quantity.push(parseInt(item.quantity)));
            setOrderInit((prev) => ({
                ...prev,
                productId: productId,
                quantity: quantity,
            }));
        }
    }, [cartArray]);

    useEffect(() => {
        if (cartArray && cartArray.no_of_carts > 0 && userDefaultAddress.loaded) {
            let itemArray = cartArray.cart.map((item) => {
                let itemObj = {
                    skuId: item.ean,
                    quantity: item.quantity,
                };
                return itemObj;
            });
            getProductServiceability(userDefaultAddress?.address?.zip, itemArray).then((res) => {
                if (res) {
                    setDeliveryEstDays({
                        loaded: true,
                        value: res,
                    });
                }
            });
        }
    }, [cartArray, userDefaultAddress]);

    const deliveryOptions = [
        {
            text: t('delivery.deliverToLocation'),
            link: "/home-delivery",
        },
    ];

    const handleSelected = (name) => {
        setSelected(name);
    };

    useEffect(() => {
        const checkBox = document.getElementById(t('delivery.deliverToLocation'));
        checkBox.click();
    }, []);

    const handlePageSwitch = (pageURL) => {
        nav(pageURL);
    };

    const breadCrumbsData = [
        {
            text: t('home'),
            url: "/",
        },
        {
            text: t('profile.cart'),
            url: "/mycart",
        },
        {
            text: t('delivery.deliveryOption'),
            url: "",
        },
    ];

    // //Get Coupon Codes
    const handleCoupon = (e) => {
        e.preventDefault();
        if (!couponInput || couponInput === "") return toast.error("Enter Coupon Code", { toastId: "no-coupon-code" });
        checkCoupon(couponInput).then((res) => {
            if (res && res.data && res.data.status === "success") {
                let coupon = res.data.data.coupon;
                let searchCart = cartArray.cart.find((item) => item.ean === coupon.products[0]);
                if (!searchCart) return toast.error(t('delivery.couponNotApplicable'));
                setAppliedCoupon(coupon);
                toast.success(`${t('delivery.couponCodeApplied')} 🎊`);
            } else {
                toast.error(t('delivery.couponCodeNotValid'), { toastId: "invalid-coupon" });
            }
        });
    };

    return (
        <div className="page_Wrapper page_Margin_Top_Secondary">
            <BreadCrumbs data={breadCrumbsData} />
            <div className="desk_Page_Wrapper">
                <aside className="side_Section" style={{ padding: "0" }}>
                    <PriceDetailsBox HideDetails={false} />
                </aside>
                <div>
                    <div className="order_Page_Right">
                        <p className="cart_Text section_Wrapper">{t('delivery.selectDeliveryOption')}</p>
                        <div className="payment_Methods delivery_Options section_Wrapper">
                            <form className="payment_Methods_Body">
                                {deliveryOptions.map((item, index) => (
                                    <div key={index}>
                                        <label
                                            htmlFor={item.text}
                                            className={`radiobtn-label payment_Methods_Labels  ${selected === item.link ? "payment_Methods_Selected" : ""}  ${item.text === "Pickup from store" ? (cartProdMoreThanOne ? "option_Disabled" : "") : ""
                                                }`}
                                            onClick={() => {
                                                handleSelected(item.link);
                                                setDisable(false);
                                            }}
                                        >
                                            <input type="radio" name="delivery" id={item.text} value={item.text} />
                                            <span className="radio-custom"></span>
                                            {item.text}
                                        </label>
                                    </div>
                                ))}
                            </form>
                        </div>
                        <form action="" style={{ marginBottom: "50px" }}>
                            <div className="cart_Coupon_Section section_Wrapper">
                                <div className="cart_Coupon_Input">
                                    <input
                                        disabled={appliedCoupon}
                                        type="text"
                                        title={appliedCoupon ? "Applied Coupon" : "Add Coupon"}
                                        placeholder={t('delivery.addCouponCode')}
                                        className={`input-field ${appliedCoupon && "coupon-applied"}`}
                                        value={couponInput}
                                        onChange={(e) => setCouponInput(e.target.value)}
                                    />
                                </div>
                                <div className="cart_Coupon_Button">
                                    {appliedCoupon ? (
                                        <button
                                            type="submit"
                                            className="submit-button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAppliedCoupon(null);
                                            }}
                                        >
                                            <p>{t('delivery.changeCoupon')}</p>
                                        </button>
                                    ) : (
                                        <button disabled={!couponInput} type="submit" className="submit-button" onClick={handleCoupon}>
                                            <p>{t('delivery.applyCoupon')}</p>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                        {matches && (
                            <div className="delivery_Option_Submit_Button">
                                <button type="submit" className="submit-button " disabled={disable} onClick={() => handlePageSwitch(selected)}>
                                    <p>{t('continue')}</p>
                                </button>
                            </div>
                        )}
                        {!matches && (
                            <div className="address_Footer">
                                <button type="submit" className="submit-button" disabled={disable} onClick={() => handlePageSwitch(selected)}>
                                    <p>{t('continue')}</p>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryOptions;
