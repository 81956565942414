// top level imports
import React, { useState } from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";
import { addStoreBulk } from "../../api/AdminApis/AdminStore";
import { toast } from "react-toastify";
import CommonModal from "../../components/ModalComponenr/CommonModal";

// toast.configure();

// Component definition
function DashboardAddShop() {
    // constants
    const requiredFields = [
        'fc_name',
        'brand_store_id',
        'warehouse_name',
        'warehouse_batch_name',
        'sale_ledger_name',
        'sale_voucher_type',
        'gst_sales_account_ledger_name',
        'credit_note_ledger_name',
        'credit_note_voucher_type',
        'pincode',
        'serviceablePincodes'
    ];

    const nav = useNavigate();

    // state definitions
    // const [imagesObject, setImagesObject] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, ] = useState("");
    const [fileUploaded, setfileUploaded] = useState({
        loaded: false,
        fileData: [],
    });

    const [fileJsonData, setFileJsonData] = useState({
        loaded: false,
        fileData: [],
    });

    const [, setFileToSend] = useState([]);
    const [canUpload, setCanUpload] = useState(false);

    // refs
    const storesRef = React.useRef([])

    const handleFile = async (e) => {
        const file = e.target.files[0];
        const data = await file?.arrayBuffer();
        /* data is an ArrayBuffer */
        if (data) {
            const workbook = XLSX.read(data);
            const workSheet = workbook.Sheets[workbook.SheetNames[0]];
            const [header, ...rows] = XLSX.utils.sheet_to_json(workSheet, {
                header: 1,
                defval: "",
                blankrows: false,
            });
            const columnData = XLSX.utils.sheet_to_json(workSheet, {
                header: 1,
                defval: "",
                blankrows: false,
            });
            setFileToSend([]);
            /* const tStore = jsonData.map((store) => {
                var pinArray_From_String = [];
                var trimmedPin_Array = [];
                if (store?.serviceablePincodes?.indexOf(",") > -1) {
                    pinArray_From_String = store.serviceablePincodes.split(",");
                    pinArray_From_String.forEach((pincode) => {
                        let trimmedPin = pincode.trim();
                        trimmedPin_Array.push(trimmedPin);
                    });
                }

                store.serviceablePincodes = [...trimmedPin_Array];
                return store
            }); */
            
            transformStoreMaster(header, rows);

            setfileUploaded({
                loaded: true,
                fileData: columnData,
                storeIdPosition: columnData[0].indexOf("brand_store_id"),
            });
            setFileJsonData({
                loaded: true,
                fileData: [header, ...rows],
            });
        } else {
            setfileUploaded({
                loaded: false,
                fileData: [],
            });

            setFileJsonData({
                loaded: false,
                fileData: [],
            });

            setFileToSend([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const storesPayload = storesRef.current;

        if (!storesPayload.length > 0) {
            toast.error('Stores data not uploaded from excel')
            return;
        }

        try {
            const response = await addStoreBulk(storesPayload);

            if (response.status === 'success') {
                toast.success('Stores added succesfully!');
                nav("../admin-shops");
            }
        } catch(err) {
            // catch error here
            let errMsg = 'Something went wrong!';
            if (err.response.data.message === "duplicate Key") {
                errMsg = 'Error in upload. Duplicate records found';
            }
            
            toast.error(errMsg);
        }
    };

    const checkRequiredFields = stores => {
        let hasError = false;
        for (const store of stores) {
            for (const prop of Object.keys(store)) {
                if (requiredFields.includes(prop) && !store[prop]) {
                    toast.error(`Required prop - ${prop} missing`);
                    hasError = true
                    break;
                }
            }
        }

        return hasError
    }

    const transformStoreMaster = (headers, rows) => {
        const stores = [];
        for (const row of rows) {
            const store = {}
            for (let i = 0; i < row.length; i++) {
                if (headers[i] === 'serviceablePincodes') {
                    const includesCommaSepValues = row[i]?.toString()?.includes(',');
                    if (includesCommaSepValues) {
                        store[headers[i]] = row[i]?.split(',')?.map(el => el.trim()) ?? []
                    } else {
                        store[headers[i]] = row[i];
                    }
                } else {
                    store[headers[i]] = row[i]
                }
                
            }
            stores.push(store)
        }

        const hasError = checkRequiredFields(stores);
        if (!hasError) {
            storesRef.current = stores;
        }

        setCanUpload(!hasError)
    }

    const modalHeaderComp = () => {
        return (
            <div className="addProduct_Preview_Modal">
                <h4>Shop Image Preview</h4>
            </div>
        );
    };

    const modalBodyComp = () => {
        return (
            <div className="image_Preview_Selected section_Wrapper modal_Image_Preview">
                <img src={modalData} alt="" />
            </div>
        );
    };

    /* const imageHandleChange = (e, type, eanNum) => {
        if (e.target.files) {
            let imgObj = {
                imgs: [],
                imgsUrl: [],
            };

            for (let i = 0; i < e.target.files.length; i++) {
                imgObj.imgs.push(e.target.files[i]);
                let url = URL.createObjectURL(e.target.files[i]);
                imgObj.imgsUrl.push(url);
            }

            if (type === "store_image") {
                if (imgObj.imgs.length > 1) {
                    e.target.value = "";
                    imgObj = {
                        imgs: [],
                        imgsUrl: [],
                    };
                    toast.error("Maximum 1 Image can be selected!");
                }
                setImagesObject({ ...imagesObject, [eanNum]: imgObj });
            }
        }
    };
    const handleOpen = (e, imagesPassed) => {
        e.preventDefault();
        setModalOpen(true);
        setModalData(imagesPassed);
    }; */

    return (
        <>
            <div className="container">
                <Link to="../admin-shops">
                    <FontAwesomeIcon icon={faChevronLeft} /> Shops List
                </Link>
                <div className="card-body bg-light shadow-sm">
                    <h3>Add Shop</h3>
                </div>
                <input
                    type="file"
                    className="input-field"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => handleFile(e)}
                    style={{ height: 'revert' }}
                />
                {fileUploaded.loaded && (
                    <>
                        <div className="catelogue_Table">
                            <table>
                                <thead>
                                    <tr>
                                        {fileUploaded.fileData[0].map((item, index) => (
                                            <th key={index}>{item}</th>
                                        ))}
                                        <th>
                                            <p>Upload Shop Image</p>
                                            <p>Add Maximum 1 image</p>
                                        </th>
                                        <th>Image File Preview</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileUploaded.fileData.map((item, index) =>
                                        index === 0 ? (
                                            ""
                                        ) : (
                                            <tr key={index}>
                                                {item.map((itm, index) => (
                                                    <td key={index}>{itm}</td>
                                                ))}
                                                {/* <td>
                            <form action="" encType="multipart/form-data">
                              <input
                                type="file"
                                name="Product Images"
                                multiple
                                id="productImageInput"
                                className="input-field"
                                accept="image/*"
                                placeholder="Enter Product Images URL"
                                onChange={(e) => imageHandleChange(e, "store_image", item[fileUploaded.storeIdPosition].toString())}
                              />
                            </form>
                          </td>
                          <td>
                            <div className={"button-Container"}>
                              <button
                                type="submit"
                                className="submit-button"
                                disabled={imagesObject[item[fileUploaded.storeIdPosition]] && imagesObject[item[fileUploaded.storeIdPosition]]?.imgs.length !== 0 ? false : true}
                                onClick={(e) => handleOpen(e, imagesObject[item[fileUploaded.storeIdPosition]].imgsUrl, index)}
                              >
                                <p>Preview Images</p>
                              </button>
                            </div>
                          </td> */}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className={"button-Container"}>
                            <button
                                id="submitCsvData"
                                disabled={!canUpload}
                                type="submit"
                                className="submit-button"
                                onClick={handleSubmit}
                            >
                                <p>Submit</p>
                            </button>
                        </div>
                    </>
                )}
            </div>
            {/* <CatelogueModal modalShow={modalOpen} setModalShow={setModalOpen} imageData={modalData} /> */}
            <CommonModal
                modalShow={modalOpen}
                setModalShow={setModalOpen}
                ModalHeaderComp={modalHeaderComp}
                ModalBodyComp={modalBodyComp}
            />
        </>
    );
}

export default DashboardAddShop;
