import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import {  ListGroup } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import ProductDetailsCard from './ProductDetailsCard';
import { getIndividualProductStockForAdmin, updateStockQtyForAdmin } from '../../../api/AdminApis/StockTracker';

const ProductStockTrakerUpdate = () => {
    const navigate = useNavigate();
    const [details, setDetails] = useState({});
    const [stockId, setStockId] = useState(null)
    const params = useParams();
    const [quantity, setQuantity] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (stockId) fetchStockDetails(stockId)
    }, [stockId]);

    useEffect(() => {
        if (!params?.stockId) {
            navigate("/admin/stock-tracker")
        }
        setStockId(params?.stockId)
    }, [params?.stockId, navigate])


    const fetchStockDetails = async (stockId) => {
        try {
            setLoading(true);
            const data = await getIndividualProductStockForAdmin(`stockId=${stockId}`);
            if (data?.status === "success") {
                setDetails(data?.data);
                setQuantity(data?.data?.quantity);
            } else {
                toast.error(`No Stock Found!!!`);
            }
        } catch (err) {
            const errorResponse = err?.response?.data?.status === "error";
            toast.error(errorResponse ? err?.response?.data?.message : `Error while fetching Stocks. Refresh the Page.`);
        } finally {
            setLoading(false)
        }
    }

    async function handleStockUpdateClick(e) {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await updateStockQtyForAdmin({
                ean: details?.product?.ean,
                storeId: details?.store?.brand_store_id,
                quantity: quantity
            });

            if (response?.status === 'success') {
                toast.success('Stock updated successfully');
                navigate(-1)
            }

        } catch (err) {
            const errorResponse = err?.response?.data?.status === "error";
            toast.error(errorResponse ? err?.response?.data?.message : `Error while fetching Stocks. Refresh the Page.`);
        }
        finally {
            setLoading(false);
        }
    }

    const editForm =
        <ListGroup.Item
            as="div"
            className="d-flex justify-content-between align-items-center"
        >
            Product Stock:
            <div className='d-flex gap-2 justify-content-center align-items-center'>
                <TextField
                    type="number"
                    size="small"
                    className="form-control fw-700"
                    variant="outlined"
                    value={quantity}
                    onChange={(e) => {
                        if (!isNaN(e.target.value))
                            setQuantity(e.target.value)
                    }}
                    inputMode="numeric"
                    style={{
                        width: "260px",
                    }}
                />
                <Button
                    variant='contained'
                    type='button'
                    disabled={Number(quantity) === Number(details?.quantity) || loading}
                    className='d-flex justify-content-center align-items-center gap-2'
                    onClick={handleStockUpdateClick}
                >
                    <span>Update</span>
                    {
                        loading &&
                        <CircularProgress size="small" sx={{
                            width: "20px",
                            height: "20px"
                        }} />
                    }
                </Button>
            </div>
        </ListGroup.Item>

    return (
        <div className="container">
            {details &&
                <ProductDetailsCard heading={'Update Stock'} details={details} editHTML={editForm} />
            }
        </div>
    );
}

export default ProductStockTrakerUpdate;
