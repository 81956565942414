import { createContext, useEffect, useState } from "react";

const OfferContext = createContext(null);

const OfferState = (props) => {
    const [OfferId, setOfferId] = useState(null);
    const [storecode, setStorecode] = useState("");

    const [offerObj, setofferObj] = useState({
        userId: null,
        offerId: null,
        offerLink: null
    });

    function changeOfferId(id) {
        setOfferId(() => id)
    }
    function changeStoreCode(sap_code) {
        setStorecode(() => sap_code)
    }

    function changeStoreInformation(obj) {
        setofferObj(prev => ({ ...prev, ...obj }))
    }

    function getOfferDetails () {
        const item = localStorage.getItem("offerObj");
        return item ?  JSON.parse(item) : {}
    }

    useEffect(() => {
        if (OfferId) {
            localStorage.removeItem("OfferId");
            localStorage.setItem("OfferId", OfferId);
        }
    }, [OfferId]);

    useEffect(() => {
        if (storecode) {
            localStorage.removeItem("storecode");
            localStorage.setItem("storecode", storecode);
        }
    }, [storecode]);

    useEffect(() => {
        if (offerObj) {
            localStorage.removeItem("offerObj");
            localStorage.setItem("offerObj", JSON.stringify(offerObj));
        }
    }, [offerObj]);

    return (
        <OfferContext.Provider value={{
            OfferId,
            changeOfferId,
            changeStoreCode,
            storecode,
            changeStoreInformation,
            getOfferDetails
        }}>
            {props.children}
        </OfferContext.Provider>
    )
}

export { OfferState };
export default OfferContext;