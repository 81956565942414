import { useEffect } from "react";
import { getAddress } from "../../api/Address";
import useSiteCtx from "../../hooks/useSiteCtx";
import useUserCtx from "../../hooks/useUserCtx";

//CSS
import "./Address.css";

//Components
import MyAddress from "./MyAddress";

//Images

const Address = () => {
    const { setEditID } = useUserCtx();
    const { setHeaderData } = useSiteCtx();

    const { setUserAddress } = useUserCtx();
    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Delivery option",
            categoriesCond: false,
        });
    }, []);

    useEffect(() => {
        getAddress().then((res) => {
            if (res) {
                setUserAddress({
                    loaded: true,
                    no_of_address: res.no_of_address,
                    address: res.address,
                });
            }
        });
    }, []);

    return (
        <MyAddress setEditID={setEditID} />
    );
};

export default Address;
