import { createContext, useState } from "react";

const AdminContext = createContext(null);

const AdminState = (props) => {
    const [allHomePageSections, setAllHomePageSections] = useState([]);

    return (
        <AdminContext.Provider
            value={{
                allHomePageSections,
                setAllHomePageSections,
            }}
        >
            {props.children}
        </AdminContext.Provider>
    );
};

export { AdminState };
export default AdminContext;
