import React, { useEffect } from "react";
import useSiteCtx from "../../hooks/useSiteCtx";
import Update from "./Update";

// Component definitions
const UpdateEmail = () => {
    const { modalDataMobile, setHeaderData } = useSiteCtx();

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Update Email Address",
            categoriesCond: false,
        });
    }, []);

    return (
        <Update
            number={false}
            oldInfo={modalDataMobile.oldData.user_Email}
            newInfo={modalDataMobile.newData.user_Email}
        />
    );
};

export default UpdateEmail;
