import React, { useEffect, useMemo, useState } from "react";
import "./WishlistProductCard.css";
import NothingImage from "../../assets/vector/noImage.svg";
import Rating from "@mui/material/Rating";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from "react-i18next";

// Utils
import { currencyFormat } from "../../utils";
import { Link, useMediaQuery } from "@mui/material";

// Component definition
const WishlistProductCard = ({ product, handleRemoveFromWishlist, handleAddToCart, isLoadingBuyNow }) => {
    const [productData, setProductData] = useState({
        id: "",
        name: "",
        color: "",
        price: {},
        image: "",
        slug: '',
        rating: 0
    });
    const { t } = useTranslation();
    const [discount, setDiscount] = useState("");
    const isMobile = useMediaQuery("(max-width: 500px)");

    useEffect(() => {
        if (product) {
            setProductData({
                id: product?._id,
                name: product?.title,
                color: product?.color,
                price: product?.price,
                image: product?.images[0],
                description: product?.description?.map(d => d),
                slug: product.slug,
                rating: product?.rating ? Number(product.rating) : 0
            });
        }
    }, [product]);

    const getDiscount = useMemo(() => {
        const productPriceObj = product?.price;
        const productDiscountObj = product?.discount;
        if (productPriceObj) {
            let productPrice = productPriceObj?.mop;

            let price = productPriceObj?.mrp;
            let discountPercentage = 0;

            // deduct flat discount
            if (productDiscountObj?.flatDiscount?.value > 0) {
                if (productDiscountObj?.flatDiscount?.discountType === "flat") {
                    productPrice -= productDiscountObj?.flatDiscount?.value;
                } else {
                    productPrice -=
                        (productPrice *
                            productDiscountObj?.flatDiscount?.value) /
                        100;
                }
            }
            if (price !== productPrice) {
                discountPercentage = Math.round(
                    ((price - productPrice) / price) * 100
                );
            }
            return {
                discountPrice: currencyFormat(productPrice),
                discountPercentage,
            };
        }

        return { discountPercentage: 0 };
    }, [product]);


    return (
        <div className="newWishListCart">
            <Link href={`/product/${productData.slug}`} className="newWishListDetailed text-decoration-none">
                <div className="newWishListDetailed1">
                    <img className="w-100 h-100" src={productData?.image ?? NothingImage} alt="yourCart" />
                </div>
                <div className="newWishListDetailed2 w-100">
                    <div>
                        <div className="newWishListname">
                            {productData?.name}
                        </div>
                        <div className="newWishListDetailed2Container">
                            <div className="newWishListDetailed2Div">
                                <p className="descWishList product-description">{productData?.description}</p>
                                <Rating
                                    name="read_only"
                                    value={productData.rating}
                                    readOnly
                                    sx={{
                                        fontSize: isMobile ? "1rem" : "",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="newWishListDetailed3">
                        {productData?.price?.mrp !== productData?.price?.mop &&
                            <h3>{currencyFormat(productData?.price?.mrp)}</h3>}
                        <div className="newWishListPrice">{getDiscount.discountPrice}</div>
                    </div>
                    {isMobile && (
                        <div className="newWishListButtonSection">
                            {/* <p className="newWishListAddedDate">
                                Item added 28 February 2023
                            </p> */}
                            <div className="newWishListAddButton">
                                <button
                                    type="submit"
                                    className="newWishListAddToCartButton"
                                    style={{
                                        background: "#1B325E",
                                        maxWidth: "490px",
                                        minWidth: isMobile ? "140px" : "",
                                    }}
                                    onClick={() => handleAddToCart(productData.id)}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                        }}
                                    >
                                        {t("product-page.add-to-cart")}
                                    </p>
                                    {isLoadingBuyNow && (
                                        <span
                                            className="spinner-border spinner-border-sm align-text-bottom ms-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                </button>
                                <button className="newWishListdeleteButton" onClick={() => handleRemoveFromWishlist(productData.id)}>
                                    <DeleteOutlineIcon
                                        sx={{
                                            color: "#818081",
                                            fontWeight: "300",
                                        }}
                                    />{" "}
                                    <span>{t("delete")}</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </Link>
            {!isMobile && (
                <div className="newWishListButtonSection">
                    {/* <p className="newWishListAddedDate">Item added 28 February 2023</p> */}
                    <div className="newWishListAddButton">
                        <button
                            type="submit"
                            className="newWishListAddToCartButton"
                            style={{
                                background: "#1B325E",
                                maxWidth: "290px",
                            }}
                            onClick={() => handleAddToCart(productData.id)}
                        >
                            <p
                                style={{
                                    color: "white",
                                }}
                            >
                                {t("product-page.add-to-cart")}
                            </p>
                        </button>
                        <button className="newWishListdeleteButton"
                            onClick={() => handleRemoveFromWishlist(productData.id)}
                        >
                            <DeleteOutlineIcon
                                sx={{
                                    color: "#818081",
                                    fontWeight: "300",
                                }}
                            />{" "}
                            <span>{t("delete")}</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WishlistProductCard;
