import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//CSS
import "./MyOrders.css";

//Components
import Section2 from "../../components/Section2/Section2";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import OrderSection from "./OrderSection";
import { getUserOrders } from "../../api/OrdersApi";
import getMixedProducts from "../../hooks/getMixedProducts";
import useUserCtx from "../../hooks/useUserCtx";
import useSiteCtx from "../../hooks/useSiteCtx";
import useProductCtx from "../../hooks/useProductCtx";
import { downloadInvoice } from "../../api/OrdersApi";
import { useTranslation } from "react-i18next";

// Component definitions
const MyOrders = () => {
    const { t } = useTranslation();
    const { setHeaderData } = useSiteCtx();
    const { allProducts } = useProductCtx();
    const { userOrderData, setUserOrderData } = useUserCtx();
    const nav = useNavigate();

    // state definitions
    const [totalOrders, setTotalOrders] = useState({ placed: [], cancel: [] })
    const [emptyOrdersProductArray, setEmptyOrdersProductArray] = useState([]);
    const [statusSelected, setStatusSelected] = useState('placed'); // placed or cancel

    async function handleInvoiceDownload(orderId) {
        const response = await downloadInvoice(orderId);
        const file = new Blob(
            [response],
            { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        URL.revokeObjectURL(file)
    }

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "My Orders ",
            categoriesCond: true,
            header3Store: true,
            header3Cart: true,
            header3Profile: true,
        });
    }, []);

    useEffect(() => {
        getUserOrders().then((res) => {
            if (res) {
                let { placed, cancel } = res;
                const cancelOrders = cancel.map(c => c.orderId);
                setTotalOrders(prev => ({ ...prev, placed, cancel: cancelOrders }))
                setUserOrderData({
                    loaded: true,
                    no_of_orders: placed.length + cancel.length,
                    orders: [...placed, ...cancelOrders]
                });
            }
        });
    }, []);


    useEffect(() => {
        setEmptyOrdersProductArray(
            getMixedProducts(allProducts.products, allProducts.np1, 10)
        );
    }, [allProducts]);

    /* const getRandomProductArr = (arr, num) => {
      const shuffledArr = [...arr].sort(() => 0.5 - Math.random());
      return shuffledArr.slice(0, num);
    };
  
    const featureProducts = getRandomProductArr(allProducts.products, 10); */

    const pageSwitch = (e) => {
        e.preventDefault();
        nav("/");
    };

    const breadCrumbsData = [
        {
            text: t('home'),
            url: "/",
        },
        {
            text: t('profile.orders'),
            url: "",
        },
    ];

    let ordersNumber = userOrderData.no_of_orders;

    function trackOrder(id) {
        nav(`/order-details/${id}`);
    }


    return (
        <div className="page_Wrapper page_Margin_Top">
            <BreadCrumbs data={breadCrumbsData} />
            {ordersNumber === 0 ? (
                <>
                    <div className="empty_order_sec">
                        <p className="empty_order_text">{t('no-orders')}</p>
                        <button
                            type="submit"
                            className="submit-button"
                            onClick={pageSwitch}
                        >
                            <p>{t('start-shopping')}</p>
                        </button>
                    </div>

                </>
            ) : (
                <>
                    <div className="desk_Page_Wrapper">
                        <div>
                            <aside className="side_Section section_Wrapper">
                                <p className="side_Section_Heading">{t('sortAndFilter.filters')}</p>
                                <label
                                    htmlFor={`on_the_way`}
                                    className="checkbox-label checkbox-item d-flex align-items-center side_Section_Checkbox"
                                >
                                    <input
                                        type="radio"
                                        name="on_the_way"
                                        id={`on_the_way`}
                                        checked={statusSelected === "placed"}
                                        onChange={() => statusSelected === "placed" ? setStatusSelected("") : setStatusSelected("placed")}
                                    />
                                    <span className="custom-checkmark"></span>
                                    {t('placed')}
                                </label>

                                <label
                                    htmlFor={`Cancelled`}
                                    className="checkbox-label checkbox-item d-flex align-items-center side_Section_Checkbox"
                                >
                                    <input
                                        type="radio"
                                        name="Cancelled"
                                        id={`Cancelled`}

                                        checked={statusSelected === "cancel"}
                                        onChange={() => statusSelected === "cancel" ? setStatusSelected("") : setStatusSelected("cancel")}
                                    />
                                    <span className="custom-checkmark"></span>
                                    {t('canceled')}
                                </label>
                            </aside>
                        </div>
                        <div>
                            <div className="orders-wrapper">
                                <p className="order_Text section_Wrapper">{t('orders-placed')}</p>

                                <OrderSection
                                    totalOrders={totalOrders}
                                    statusSelected={statusSelected}
                                    trackOrder={trackOrder}
                                    handleInvoiceDownload={handleInvoiceDownload}
                                />

                            </div>
                        </div>
                    </div>
                </>
            )}
            <Section2
                id={"Top-sellers-sec"}
                heading={t('suggestedProducts')}
                productData={allProducts}
                productArray={emptyOrdersProductArray}
            />
        </div>
    );
};

export default MyOrders;
