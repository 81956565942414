import axios from "../axiosInstance";

//ADMIN LOGIN   
export const retailerLogin = async (data) => {
    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
      };
    
  let response;
  await axios
    .post(`${process.env.REACT_APP_BASE_URL}/retailer/login`, data,  {headers})
    .then((res) => {
        response = res.data;
    })
    .catch((err) => response = err.response);

  return response;
};