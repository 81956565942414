import React, { useEffect, useState } from 'react';
import useUserCtx from '../../hooks/useUserCtx';
import { replyToProductComments, getNestedComments } from '../../api/Product';
import { toast } from 'react-toastify';
import { Button, FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@mui/material';
import { addLikeStoreComment, addNewStoreComment, getNestedStoreComments, getStoreComment, removeLikeStoreComment } from '../../api/AdminApis/AdminStore';
import StoreReviewsCard from './StoreReviewsCard';
import { useTranslation } from 'react-i18next';

function StoreReview({ storeId }) {
    const [reviewsList, setReviewsList] = useState([]);
    const { userContext } = useUserCtx();
    const { t } = useTranslation();
    const [isMore, setIsMore] = useState(false);
    const [totalReviews, setTotalReviews] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = React.useState("default");
    const reviewsPerPage = 5;
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        fetchProductReviewsBySort(sortBy)
    }, [sortBy])

    const fetchStoreReviews = async (page = currentPage) => {
        try {
            let url = `?limit=${reviewsPerPage}&page=${page}&storeId=${storeId}&sortBy=${sortBy}`;
            const response = await getStoreComment(url);
            if (response.data?.status === "success" && response.data?.data?.results) {
                if (response.data?.data?.next) {
                    setIsMore(true);
                    setCurrentPage(response.data.data.next.page)
                } else {
                    setIsMore(false);
                }
                setReviewsList(prev => page === 1 ? [...response.data.data.results] : [...prev, ...response.data.data.results]);
                setTotalReviews(response.data.data.total)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? t('writeToUs.error'));
        }
    }

    const fetchProductReviewsBySort = async (sort = "default") => {
        try {
            setSortBy(sort)
            let url = `?limit=${reviewsPerPage}&page=${1}&storeId=${storeId}&sortBy=${sort}`;
            const response = await getStoreComment(url);
            if (response.data?.status === "success" && response.data?.data?.results) {
                if (response.data?.data?.next) {
                    setIsMore(true);
                    setCurrentPage(response.data.data.next.page)
                } else {
                    setIsMore(false);
                }
                setReviewsList(prev => [...response.data.data.results]);
                setTotalReviews(response.data.data.total)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? t('writeToUs.error'));
        }
    }

    const onLikeReviews = async (review) => {
        try {
            if (!userContext?.JWT) return toast.info("Login required")
            const payload = {
                commentId: review._id,
                userId: userContext.id,
                userHasVisited: review?.userHasVisited
            }
            if (review.likes.includes(userContext.id)) {
                const response = await removeLikeStoreComment(payload);
                if (response.data?.status === "success") {
                    toast.success(t("notifications.LikeRemovedForThatComment"));
                    fetchStoreReviews(1);
                }
            } else {
                const response = await addLikeStoreComment(payload);
                if (response.data?.status === "success") {
                    toast.success(t("notifications.LikeAddedForThatComment"));
                    fetchStoreReviews(1);
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? t('writeToUs.error'));
        }
    }

    const onReplyToReview = async (reviewId, text, parentReviewId) => {
        try {
            const payload = {
                review: text,
                userId: userContext.id,
                rating: 1,
                storeId: storeId,
                hasVisited: false,
            }
            if (parentReviewId) {
                payload.commentId = parentReviewId;
                payload.repliedTo = reviewId
            } else {
                payload.commentId = reviewId
            }
            const response = await addNewStoreComment(payload);
            if (response.data.status === "success") {
                toast.success('Reply sent!');
                fetchStoreReviews();
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? t('writeToUs.error'));
        }
    }

    const onShowMoreClick = async (commentId) => {
        try {
            const response = await getNestedStoreComments(commentId);
            if (response.data.status === "success") {
                return response.data.data;
            }
        } catch (error) {
            toast.error(error.response.data.message ?? t('writeToUs.error'));
        }
        return [];
    }

    useEffect(() => {
        fetchStoreReviews();
    }, []);

    return (
        <div className="flex-container d-flex justify-content-between w-100 gap-3 flex-wrap">
            {/* Reviews */}
            <div className="child review-child-1">
                <div className='d-flex justify-content-between align-items-center mt-2'>
                    <span className='reviewHead'>{t('Reviews')} <span style={{ color: "#3C96DB" }}>{`(${totalReviews})`}</span></span>
                    <div style={{
                        background: "#EEEEEE",
                        height: "2px",
                        width: "55%"
                    }}></div>
                    {reviewsList.length > 0 &&
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="sort-by">{t('sortAndFilter.sort-by')}</InputLabel>
                            <Select
                                labelId="sort-by"
                                id="demo-controlled-open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={sortBy}
                                label="Sort By"
                                onChange={handleChange}
                                size='small'
                            >
                                <MenuItem value="default">
                                    <em>{t('all')}</em>
                                </MenuItem>
                                <MenuItem value={'mostLiked'}>{t('MostLiked')}</MenuItem>
                                <MenuItem value={'highestRating'}>{t('HighesttoLowestRating')}</MenuItem>
                                <MenuItem value={'lowestRating'}>{t('LowesttoHighestRating')}</MenuItem>
                            </Select>
                        </FormControl>
                    }
                </div>

                {reviewsList.length > 0 ? reviewsList.map((review, index) =>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginBottom: "1rem" }}>
                        <StoreReviewsCard review={review} onLikeReviews={onLikeReviews} onReplyToReview={onReplyToReview}
                            onShowMoreClick={onShowMoreClick} />
                       {index !== reviewsList.length - 1 && <hr className="horizontal-line" style={{margin: 0}}/>}
                    </div>
                ) : <h6 className='mt-3 text-center'>{t('NoReviewsadded')}</h6>}
                {isMore && <Button variant='contained' size='small' sx={{background: "var(--primaryDarkBlue)"}} onClick={() => fetchStoreReviews()}>
                    {t('ShowMore')}
                </Button>}
            </div>
        </div>
    )
}

export default StoreReview