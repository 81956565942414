import React from 'react'

const Shimmer = () => {
  return (
    <div className='shimmer_Wrapper'>
      <div className="shimmer"></div>
    </div>
  )
}

export default Shimmer