import axios from "../axiosInstance";

//Product Reviews
export const getAdminProductComments = async (filters = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `bearer ${adminToken}`,
  };

  let adminProductComments;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/product/comments/admin${filters}`, { headers }).then((res) => {
    adminProductComments = res.data;
  }).catch(err => adminProductComments = err?.response)

  return adminProductComments;
};

export const changeProductCommentStatus = async (commentId) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `bearer ${adminToken}`,
  };

  let response;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/product/comments/admin/review`, {commentId}, { headers }).then((res) => {
    response = res.data;
  }).catch(err => response = err?.response)

  return response;
};

//Store Reviews
export const getAdminStoreComments = async (filters = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `bearer ${adminToken}`,
  };

  let adminProductComments;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/store/comments/admin${filters}`, { headers }).then((res) => {
    adminProductComments = res.data;
  }).catch(err => adminProductComments = err?.response)

  return adminProductComments;
};

export const changeStoreCommentStatus = async (commentId) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `bearer ${adminToken}`,
  };

  let response;

  await axios.post(`${process.env.REACT_APP_BASE_URL}/store/comments/admin/review`, {commentId}, { headers }).then((res) => {
    response = res.data;
  }).catch(err => response = err?.response)

  return response;
};