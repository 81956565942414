import { useNavigate } from "react-router-dom";
import { useContext, useRef, useMemo, useState, useEffect } from "react";
import { toast } from "react-toastify";
import OfferContext from "../../Contexts/OffersContext";
import spinWheelInner from "../../assets/IFD/spin.png";
import spinWheelOuter from "../../assets/IFD/spin-outer.png";
import spinArrow from "../../assets/IFD/arrow.png";
import { revealOffer } from "../../api/OfferAPIs";

const audioUrl = "https://olineoindia.com/Offers/lagaAudio.mp3";

function SpinNWin() {
    const {
        OfferId,
        changeStoreInformation
    } = useContext(
        OfferContext
    );
    const navigate = useNavigate();
    const [receivedPrize, setReceivedPrize] = useState(2);
    const wheelInnerRef = useRef(null);
    const [isOfferReceived, setIsOfferReceived] = useState(false);

    let audio = null;
    const [isPlayed, setIsPlayed] = useState(false);


    // 1--> 100 CB
    // 2 --> 200 CB
    // 3 --> 500 CB
    // 4 --> 1000 CB
    // 5 --> 5000 CB
    // 6 --> 10000 CB
    // 7 --> 10% discount
    // 8 --> 15% discount
    // 9 --> 20% discount
    // 10 --> Bumper Trip

    const rotationValues = useMemo(() => {
        return [
            {
                prizeIndex: 1, rotation: [1],
            },
            {
                prizeIndex: 2, rotation: [10],
            },
            {
                prizeIndex: 3, rotation: [9]
            },
            {
                prizeIndex: 4, rotation: [8],
            },
            {
                prizeIndex: 5, rotation: [3],
            },
            {
                prizeIndex: 6, rotation: [6],
            },
            {
                prizeIndex: 7, rotation: [7],
            },
            {
                prizeIndex: 8, rotation: [5],
            },
            {
                prizeIndex: 9, rotation: [2],
            },
            {
                prizeIndex: 10, rotation: [4],
            },
        ]
    }, []);

    const rotate = rotationValues.find(e => e.prizeIndex === receivedPrize).rotation[0];

    const playAudioInLoop = () => {
        audio = new Audio(audioUrl);
        audio.volume = 0.8;
        audio.play();
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await revealOffer(OfferId);
                if (data?.status === "error") {
                    toast.error(data?.message ?? "Something went wrong");
                    navigate("/Republic-Week-Offer");
                }
                else {
                    if (data?.offerIndex) {
                        setReceivedPrize(data.offerIndex);
                        changeStoreInformation({
                            userId: OfferId,
                            offerId: data.offerIndex,
                            offerLink: data.imageUrl
                        });
                    }
                    else toast.error("Something went wrong");
                }
            } catch (error) {
                toast.error("Something went wrong");
            }
        })();

        return () => {
            if (audio) {
                audio.pause();
            }
        };
    }, []);

    function spinFunc() {
        if (!wheelInnerRef.current) return
        wheelInnerRef.current.classList.toggle("wheel__moving");
        wheelInnerRef.current.style.setProperty('--new-rotate-value', `${Number(36 * rotate) + 3600 - 25}deg`);
        setIsOfferReceived(true);
        playAudioInLoop();
        setIsPlayed(true);
    }

    useEffect(() => {
        if (isOfferReceived === false) return
        const delay = 14000;

        const redirectTimeout = setTimeout(() => {
            navigate('/Republic-Week-Offer/5');
        }, delay);

        return () => {
            clearTimeout(redirectTimeout);
        };
    }, [isOfferReceived]);


    return (
        <div className="container mt-4 sub-container text-center">
            <div className="prize-container">
                <img alt="arrow" className="spinArrow" src={spinArrow} />
                <img alt="spinWheelOuter" className="spinWheelOuter" src={spinWheelOuter} />
                <img alt="spinWheelInner" ref={wheelInnerRef} className="spinWheelInner" src={spinWheelInner} />
            </div>

            <button
                onClick={spinFunc}
                type="button"
                className="btn btn-warning fw-bolder page_1_submit_btn fourth-page-btn"
                style={{
                    width: "120px"
                }}
                disabled={isPlayed}
                id="submit-otp-btn"
            >
                <span>Play</span>
            </button>
        </div>
    );
}



export default SpinNWin;