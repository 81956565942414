import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/dashboard.css";
import Content from "../../components/DashboardContent/Content";
import RetailerSideBar from "./RetailerSideBar";
import { useAdminOrRetailer } from "../../utils/useAdminOrRetailer";
import { useNavigate } from "react-router-dom";
import { getIndiStore } from "../../api/AdminApis/AdminStore";
import { useRetailerContext } from "../../Contexts/RetailerContext";
import { toast } from "react-toastify";

// Component definition
function RetailerDashboard() {

    // state definitions
    const [isOpen, setIsOpen] = useState(false);
    const [previousWidth, setPrevWidth] = useState(-1);
    const retailer = useAdminOrRetailer(process.env.REACT_APP_RETAILER_TOKEN_KEY);
    const navigate = useNavigate();
    const { setLoading, setStore, store } = useRetailerContext();

    const updateWidth = useCallback(() => {
        const width = window.innerWidth;
        const widthLimit = 576;
        const isMobile = width <= widthLimit;
        const wasMobile = previousWidth <= widthLimit;

        if (isMobile !== wasMobile) {
            setIsOpen(!isMobile);
        }

        setPrevWidth(width);
    }, [previousWidth])

    //Fetch Store Details
    const fetchStoreDetails = useCallback(async (storeId) => {
        try {
            setLoading(true);
            const response = await getIndiStore(storeId);
            const payload = response.data;
            if (payload.status === "success") {
                setStore(payload.data.store);
            }
        } catch (error) {
            toast.error("Something Went wrong")
        } finally {
            setLoading(false);
        }
    }, [setLoading, setStore])

    /**
     * Add event listener
     */
    useEffect(() => {
        if (!retailer) {
            navigate("/retailer-login")
        } else fetchStoreDetails(retailer.id)
        updateWidth();
        window.addEventListener("resize", updateWidth());

        return () => {
            window.addEventListener("resize", updateWidth());
        };
    }, [fetchStoreDetails, navigate, retailer, updateWidth]);



    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="dashboard_Whole_Wrapper">
                <RetailerSideBar toggle={toggle} isOpen={isOpen} />
                <div className="DashboardWrapper">
                    <Content toggle={toggle} isOpen={isOpen} userType={'retailer'} heading={store?.fc_name} />
                </div>
            </div>
        </>
    );
}

export default RetailerDashboard;
