import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { cancelOrderItem } from "../../api/OrdersApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import SignInBg from "../../assets/productIcons/SignUpBG.svg"

// Init toast
// toast.configure();

// Component definition
const OrderCancel = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const loc = useLocation();
    const nav = useNavigate();

    // state definitions
    const [cancelSelected, setCancelSelected] = useState('');
    const [productData, setProductData] = useState({
        order_Id: "",
        order_Status: "",
        itemId: "",
    });

    useEffect(() => {
        if (loc && loc.state) {
            setProductData({
                order_Id: loc.state.order_Id,
                order_Status: loc.state.order_Status,
                itemId: loc.state.itemId,
            });
        }
    }, [loc]);


    const orderCancelArray = useMemo(() => {
        return [
            {
                id: 1,
                data: t('order-cancel.change-address'),
                text: "I want to change address"
            },
            {
                id: 2,
                data: t('order-cancel.received-broken'),
                text: "Received a broken item"
            },
            {
                id: 3,
                data: t('order-cancel.too-long'),
                text: "Too long to be delivered"
            },
            {
                id: 4,
                data: t('order-cancel.bad-quality'),
                text: "Bad quality products feedback"
            },
            {
                id: 5,
                data: t('order-cancel.not-listed'),
                text: "My reason is not listed",
            },
            {
                id: 6,
                data: t('order-cancel.mistake'),
                text: "My order is placed by mistake",
            },
            {
                id: 7,
                data: t('order-cancel.wrong-size'),
                text: "Received  wrong size"
            },
            {
                id: 8,
                data: t('order-cancel.wrong-item'),
                text: "Received  wrong Item"
            },
        ];
    }, [])


    /* const handleOrderCancel = async (e) => {
        e.preventDefault();
        let query =  orderCancelArray[cancelSelected]?.text ?? 'No specific reason';
        const res = await cancelOrder(id, query);
        if (res.data.status === "success") {
            toast.success(t('order-cancel.created'));
            nav("/orders");
        } else {
            toast.error(t('writeToUs.error'));
        }
    }; */

    const handleOrderItemCancel = async (e) => {
        e.preventDefault();
        let query =  orderCancelArray.find(el => el.id == cancelSelected)?.text ?? 'No specific reason';
        const res = await cancelOrderItem(id, query);
        if (res.data.status === "success") {
            toast.success(t('order-cancel.created'));
            nav("/orderCancelled");
        } else {
            toast.error(t('writeToUs.error'));
        }
    }

    const handleQueryChange = (queryId) => {
        setCancelSelected(queryId)
    }

    return (
        <>
            <div className="page_Wrapper page_Margin_Top_Secondary">
                <div style={{
                    background: `url(${SignInBg}) no-repeat`,
                    // backgroundSize: "cover"
                    backgroundPosition: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    marginTop: '20px',
                    backgroundSize: 'contain'
                }}>
                <div className="order_Details_Wrapper">
                    <div className="order_Cancel_Container">
                        {orderCancelArray.map((item) => (
                            <div className="option-wrapper" key={item.id}>
                                <label
                                    htmlFor={item.id}
                                    className={`radiobtn-label  payment_Methods_Labels ${cancelSelected === item.id ? "payment_Methods_Selected" : ""}`}
                                    onClick={() => handleQueryChange(item.id)}
                                >
                                    <input
                                        name="query"
                                        value={cancelSelected}
                                        type="radio"
                                        checked={cancelSelected == item.id}
                                    />
                                    <div className="Accor-dot" data-filterselected={cancelSelected == item.id && "selected"}></div>
                                    <span className="option-text">{item.data}</span>
                                </label>
                            </div>
                        ))}
                        <div className="order_Cancel_Submit">
                            <button
                                type="submit"
                                className="submit-button w-75"
                                onClick={handleOrderItemCancel}
                                disabled={!cancelSelected}
                            >
                                <p>{t('submit')}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="floating_Footer">
                <div className="floating_Footer_Center">
                    <button
                        type="submit"
                        className="submit-button"
                        onClick={handleOrderItemCancel}
                        disabled={!cancelSelected}
                    >
                        <p>{t('submit')}</p>
                    </button>
                </div>
            </div>
            </div>
        </>
    );
};

export default OrderCancel;
