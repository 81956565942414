import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//CSS
import "./ProductBox.css";

// Utils
import { formatNumber } from "../../utils/index"

const ProductBox = ({ card_heading, classes, product }) => {
    const [productInfo, setProductInfo] = useState({
        image: "",
        name: "",
        price: "",
    });

    useEffect(() => {
        if (!product) return;
        let productPrice = product?.price?.discountPrice ? product?.price?.discountPrice : product?.price?.mop;

        // deduct flat discount
        if (product.discount?.flatDiscount?.value > 0) {
            if (product.discount?.flatDiscount?.discountType === 'flat') {
                productPrice -= product.discount?.flatDiscount?.value;
            } else {
                productPrice -= productPrice * product.discount?.flatDiscount?.value / 100;
            }
        }

        productPrice = formatNumber(productPrice)

        if (product) {
            setProductInfo((prev) => ({
                ...prev,
                name: product?.title,
                price: productPrice,
                image: product?.images?.length > 0 ? product?.images[0] : product?.images,
            }));
        }
    }, [product]);

    if (!product) return <></>;

    // console.log(product);
    return (
        <>
            {product && (
                <Link to={`/product/${product?.slug}`} className={"productbox_Container " + (classes ? classes.boxClass : "")}>
                    <>
                        {product?.images?.length > 0 && <img src={productInfo.image} alt="" />}
                        <div className="productbox_details">
                            <p className="box_heading">{card_heading}</p>
                            {productInfo?.price && <p className="box_price">₹{productInfo?.price}</p>}
                            {productInfo?.name && <p className="box_itemName">{productInfo?.name}</p>}
                        </div>
                    </>
                </Link>
            )}
        </>
    );
};

export default ProductBox;
