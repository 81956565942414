import axios from "../axiosInstance";

export const addStoreBulk = async (stores) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    let addStoreBulkRes;
    await axios.post(`${process.env.REACT_APP_BASE_URL}/store/`, JSON.stringify(stores), { headers }).then((res) => {
        addStoreBulkRes = res.data;
    });

    return addStoreBulkRes;
};

export const getAllStore = async (query = "") => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };

    let getStore;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/store?${query}`, { headers }).then((res) => {
        getStore = res.data?.data;
    });

    return getStore;
};

export const getAllStoreIds = async () => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };

    let getStoreIds;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/store/all-id`, { headers }).then((res) => {
        getStoreIds = res.data;
    });

    return getStoreIds;
};

export const getIndiStore = async (id) => {
    let getStore;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/store/${id}`).then((res) => {
        getStore = res;
    });

    return getStore;
};

export const updateIndiStore = async (id, data) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };

    let updateStoreRes;

    await axios.patch(`${process.env.REACT_APP_BASE_URL}/store/${id}`, JSON.stringify(data), { headers }).then((res) => {
        updateStoreRes = res;
    });

    return updateStoreRes;
};

export const deleteIndiStore = async (id) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };

    let deleteStoreRes;

    await axios.delete(`${process.env.REACT_APP_BASE_URL}/store/${id}`, { headers }).then((res) => {
        deleteStoreRes = res;
    });

    return deleteStoreRes;
};

export const getOrderFromStore = async (id) => {
    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    let getOrders = [];

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/pos/order?storeId=${id}`, { headers }).then((res) => {
        getOrders = res.data
    });

    return getOrders;
};

export const getOrderWithInvoice = async (storeId) => {
    const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/pos/orderWithInvoice?storeId=${storeId}`, { headers })
    return response.data;

}

// get store average rating details
export const getStoreRatingDetails = async (storeId) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/store/ratingDetails/${storeId}`)
}

// add comment for store
export const addNewStoreComment = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.post(`${process.env.REACT_APP_BASE_URL}/store/comments`, payload, headers);
}

// get store reviews
export const getStoreComment = async (url) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/store/comments${url}`, headers);
}

// add like to existing stores reviews
export const addLikeStoreComment = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.post(`${process.env.REACT_APP_BASE_URL}/store/comments/like`, payload, headers);
}

// remove like to existing stores reviews
export const removeLikeStoreComment = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/store/comments/like`, { data: payload }, headers);
}

// get nested store comments
export const getNestedStoreComments = async (parentId) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/store/comments/parent/${parentId}`, headers);
}
