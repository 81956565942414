import React from "react";
import NewAddressForm from "../../components/NewAddress/NewAddress";

const NewAddress = () => {
  return (
    <>
        <NewAddressForm fromProfile={false} />
    </>
  );
};

export default NewAddress;
