import React, { useEffect, useState } from 'react';
import DashboardLoader from '../../components/DashboardContent/DashboardLoader';
import * as XLSX from "xlsx";
import { Button } from 'react-bootstrap';
import { currencyFormat, formatDate } from '../../utils';
import { getAllUsersForifdForRetailer } from '../../api/OfferAPIs';
import { useRetailerContext } from '../../Contexts/RetailerContext';

// Component definition
const RetailerIFD = () => {
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const { store } = useRetailerContext();

    function getCurrentDateTime() {
        const now = new Date();

        // Get the day, month, and year components
        const day = now.getDate().toString().padStart(2, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const year = now.getFullYear().toString().slice(-2);

        // Get the hours and minutes
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        const minutes = now.getMinutes().toString().padStart(2, '0');

        // Combine the components into the desired format
        const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }

    function downloadFile() {
        const currentDateTime = getCurrentDateTime();
        const filename = `IFD_Users_${currentDateTime}.xlsx`;
        const selectedData = data.map((d, index) => ({
            "S.No.": index + 1,
            Date: formatDate(d.createdAt),
            UserName: d?.userName,
            User_Email: d?.userEmail,
            User_Phone: d?.userPhone,
            Store_ID: d?.storeId,
            "Store Name": d?.storeName,
            "Prize Won": d?.offerText,
            "Prize Avail Time": d?.offerAvailTime ? formatDate(d.offerAvailTime) : "-",
            "Store Verified": d?.isStoreVerified,
            "Product Category": d?.productCategory,
            "Brand Name": d?.brandName,
            "Product Name": d?.productName,
            "Invoice Amount": d?.productAmount,
            "Invoice Number": d?.invoiceNumber,
        }));
        var ws = XLSX.utils.json_to_sheet(selectedData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Users-IFD-2023");
        XLSX.writeFile(wb, filename);
    }

    useEffect(() => {
        if (!store?._id) return
        setLoader(true);
        getAllUsersForifdForRetailer(store._id).then((res) => {
            if (res?.status === "success") {
                setData(res.users);
            }
        }).finally(() => setLoader(false))

    }, [store?._id]);


    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3>All Users for IFD 2023</h3>
                </div>
                <h1>
                    <Button variant='primary' size='small' onClick={downloadFile}>Download Users</Button>
                </h1>
                <div className="table-responsive">
                    {
                        data?.length > 0 ?
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile Number</th>
                                        <th scope="col">Store ID</th>
                                        <th scope="col">Store Name</th>
                                        <th scope="col">Prize Won</th>
                                        <th scope="col">Prize Won Time</th>
                                        <th scope="col">Prouct Category</th>
                                        <th scope="col">Brand Name</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Invoice Amount</th>
                                        <th scope="col">Invoice Number</th>
                                        <th scope="col">Store Verified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr key={item._id}>
                                            <td>{index + 1}.</td>
                                            <td>{item?.createdAt ? formatDate(item.createdAt) : "-"}</td>
                                            <td> {item?.userName ?? "-"} </td>
                                            <td>{item?.userEmail ?? "-"}</td>
                                            <td>{item?.userPhone ?? "-"}</td>
                                            <td>{item?.storeId ?? "-"}</td>
                                            <td>{item?.storeName ?? "-"}</td>
                                            <td>{item?.offerText ?? "-"}</td>
                                            <td>{item?.offerAvailTime ? formatDate(item.offerAvailTime) : "-"}</td>
                                            <td>{item?.productCategory ?? "-"}</td>
                                            <td>{item?.brandName ?? "-"}</td>
                                            <td>{item?.productName ?? "-"}</td>
                                            <td>{item?.productAmount ? currencyFormat(item.productAmount) : "-"}</td>
                                            <td>{item?.invoiceNumber}</td>
                                            <td>{item?.isStoreVerified ? "Yes" : "No"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            :
                            <h1 className='my-4'>No Users Found!!!</h1>
                    }
                </div>
            </div>
        </>
    );
};

export default RetailerIFD;