import React, { useEffect, useRef } from 'react'
import styles from "./_IFD.module.css";

const PreLoader = () => {
    const preloader = useRef(null)
    const removePreloader = () => {
        preloader.current.style.display = "none";
    }
    useEffect(() => {
        if (document.readyState === "interactive") return removePreloader();
        window.addEventListener("DOMContentLoaded", removePreloader, { once: true });
    }, [document.readyState]);
    return (
        <div ref={preloader} className={styles['preloader']}>
            <svg style={{ margin: 'auto', background: 'rgba(241, 242, 243, 0)', display: 'block' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                {[20, 40, 60, 80].map((translateX, index) => (
                    <g key={index} transform={`translate(${translateX} 50)`}>
                        <circle cx={0} cy={0} r={5} fill="#411a7a">
                            <animateTransform attributeName="transform" type="translate" begin={`-${index * 0.2}s`} calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values={`0,0;0,5;0,0`} keyTimes="0;0.5;1" dur="0.5s" repeatCount="indefinite" />
                        </circle>
                    </g>
                ))}
            </svg>
        </div>
    )
}

export default PreLoader