import axios from "../axiosInstance";

export const getQuiry = async (filters = "") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  let allQuirys;

  await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/query${filters}`, { headers }).then((res) => {
    allQuirys = res.data?.data;
  });

  return allQuirys;
};

// gets a query by its Id
export const getQueryById = async (queryId) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `bearer ${adminToken}`,
  };

  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/query/${queryId}`, { headers });
  return response.data;
}


export const changeQueryStatus = async (newQuery, queryId) => {
  let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

  const headers = {
    Authorization: `bearer ${userToken}`,
    'content-type': 'application/json'
  };

  let requestBody = JSON.stringify({
    newQuery,
    queryId
  });

  let responseStatus;

  await axios.patch(`${process.env.REACT_APP_BASE_URL}/user/admin/query/changeStatus`, requestBody, { headers }).then((res) => {
    responseStatus = res.data?.data?.status;
  });

  return responseStatus;
};

// fetches a catgeory by its id or all categories of query
export const getQueryCategories = async (categoryId = "all") => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/admin/query/category/${categoryId}`, { headers });
  return response.data;
}

// deletes a query category by its id
export const deleteQueryCategory = async (categoryId = null) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/user/admin/query/category/${categoryId}`, { headers });
  return response.data;
}

// adds a query category
export const createQueryCategory = async (categoryObj) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  const payload = categoryObj;

  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/admin/query/category/create`, payload, { headers });
  return response.data;
}

// updates a query category
export const updateQueryCategory = async (categoryObj) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  // request payload and param
  const { _id, ...payload } = categoryObj;
  const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/user/admin/query/category/${_id}`, payload, { headers });
  return response.data;
}

// admin sends response to a customer query
export const submitQueryResponse = async (values) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  // request payload and param
  const { _id, ...payload } = values;
  const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/user/admin/query/respond/${_id}`, payload, { headers });
  return response.data;
}