import React, { useEffect, useState } from "react";
import "./findStore.css";
import FindStoreMap from "./findStoreMap";
import FindStoreContact from "./findStoreContact";
import { getAllStoresForLocation } from "../../api/StoreApi";
import Loader from "../../components/Loader/Loader";
import useGeolocation from "../../hooks/useGeolocation";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import locationWarningYellowIcon from "../../assets/vector/location_warning_yellow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const storeAreaName = [
    "mumbai",
    "nashik",
    "bangalore",
    "jaipur",
    "ajmer"
]

export default function LocationWiseStores({ storeLocations }) {
    const { location, locationFetch } = useGeolocation();
    const { setUserLocation } = useUserCtx();
    const [storeLocation, setStoreLocation] = useState("");
    const routeLocation = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const [stores, setStores] = useState([]);

    const breadCrumbsData = [
        {
            text: t('home') + " / ",
            url: "/",
            isActive: routeLocation.pathname === "/"
        },
        {
            text: t('storeFinder'),
            url: "/store-finder",
            isActive: routeLocation.pathname === "/store-finder"
        },
    ];

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        const areaName = routeLocation.pathname.split("/")[2];
        if (!storeAreaName.includes(areaName?.toString()?.toLowerCase())) {
            navigate("/store-finder")
        }
        setStoreLocation(areaName);
        getAllStoresForLocation(areaName).then(res => {
            if (res?.status === "success") {
                setStores(res?.data?.stores ?? []);
                setShowLoader(true);
            } else {
                toast.error(t('writeToUs.error'))
            }
        }).finally(() => setShowLoader(false))
    }, [routeLocation, navigate, t]);

    useEffect(() => {
        if (location.loaded && location.coordinates) {
            setUserLocation({
                loaded: true,
                useThis: false,
                coordinates: location.coordinates,
                address: location.address,
            });
        }
    }, [location]);

    const refreshPage = () => {
        window.location.reload(false);
    };

    return (
        <div className="findStoreContainer">
            <h1 className="findStoreTitle">
                {breadCrumbsData.map((item, index) =>
                    <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
                    </Link>)}</h1>
            <div id="outermostDiv">
                <h1 className="findStoreMapTitle">O-LINE-O Stores At <span>{storeLocation}</span></h1>
                {location && location.loaded && location.error && (
                    <>
                        <div className="location_Alert">
                            <img src={locationWarningYellowIcon} alt="locationWarningYellowIcon" />
                            <p>
                                {t('store.enable-from-browser')}
                            </p>
                        </div>
                        <div className="location_Refresh">
                            <p>{t('store.refresh-page')}</p>
                            <div className="location_Refresh_Button">
                                <button
                                    type="submit"
                                    className="submit-button"
                                    onClick={refreshPage}
                                >
                                    <p>{t('store.refresh')}</p>
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {
                    !location.error &&
                    (showLoader ?
                        <Loader />
                        :
                        stores.length > 0 ?
                            <>
                                <FindStoreMap
                                    stores={stores}
                                    zoomLevel={8.4}
                                />
                                <FindStoreContact
                                    stores={stores}
                                    totalStores={stores.length}
                                />
                            </>
                            :
                            <div className="nostore_Found_Text">
                                <p>{t('store.no-store')}</p>
                            </div>
                    )
                }
            </div >
        </div >
    );
}