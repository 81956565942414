import React, { useCallback, useEffect, useMemo, useState } from "react";
import Section1 from "../../components/Section1/Section1";

import { getHomePageSections } from "../../api/AdminApis/HomePageSections";
import useSiteCtx from "../../hooks/useSiteCtx";
import useProductCtx from "../../hooks/useProductCtx";

// Utils
import { useTranslation } from "react-i18next";
import RightBanner from "../../assets/productIcons/rightBanner.svg";
import northIcon from "../../assets/vector/northIcon.svg";

import useScreenSize from "../../utils/ScreenSizeCalculation";
import RetailBanner from "./RetailerBanner";
import "./RetailStorePage.css";
import GetinTouchPage from "./GetinTouchPage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductCard from "../../components/Cards/ProductCard";
import { ClickAwayListener, Drawer, Grow, MenuItem, MenuList, Pagination, Paper, Popper, useMediaQuery, CircularProgress, Rating } from "@mui/material";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DrawerSection from "../ProductCategory/DrawerSection";
import { toast } from "react-toastify";
import SegmentIcon from "@mui/icons-material/Segment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { getFiltersForStoreProducts, getSearchedProductForStore } from "../../api/StoreApi";
import { getIndiStore, getStoreRatingDetails } from "../../api/AdminApis/AdminStore";
import MapWrapper from "../../components/MapComp/MapWrapper";
import StoreReviewModal from "../../components/StoreReview/StoreReviewModal";
import StoreReview from "../../components/StoreReview/StoreReviews";

// Component Definition
const RetailStorePage = () => {
  const { t } = useTranslation();
  const { allProducts } = useProductCtx();
  const { setHomePageSections } = useSiteCtx();
  const urlParams = useParams();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:568px)");

  const { userContext, userWishlist, userLocation, cartArray } = useUserCtx();

  const [minMaxPrice, setMinMaxPrice] = useState({ highest: 100, lowest: 0 });
  const [priceRange, setPriceRange] = useState([minMaxPrice.lowest, minMaxPrice.highest]);
  const [step, setStep] = useState(1);

  const scrollToProductCard = () => {
    document.querySelector(".order_Page_Right_res").scrollIntoView()
  }

  const handleChange = (event, newValue) => {
    setPriceRange(() => newValue);
    scrollToProductCard()
  };

  function handlePriceRangeSubmit() {
    setFilterSelected((prev) => prev.some(e => e.type === "price.mop") ?
      [...prev.filter(e => e.type !== "price.mop"), { type: "price.mop", searchQ: priceRange.join("-") }] :
      [...prev, { type: "price.mop", searchQ: priceRange.join("-") }]
    );
    isMobile && handleClose()
    scrollToProductCard()
  }

  function handlePriceReset() {
    setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
    isMobile && handleClose()
    scrollToProductCard()
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterSelected, setFilterSelected] = useState([]);
  const [totalProducts, setTotalProducts] = useState(1);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [sorting, setSorting] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const [filterListData, setFilterListData] = useState([]);
  const [reviewDetails, setReviewDetails] = useState({
    ratingAvg: 0,
    totalReviews: 0,
    totalSolds: 0
  });
  const [openReviewModal, setOpenReviewModal] = useState(false);

  const { width } = useWindowDimensions();

  const matches = width >= 768;
  const nav = useNavigate();

  const { searchedProduct, setSearchedProduct, currentPage, setCurrentPage } =
    useProductCtx();
  const productsPerPage = 12;

  const handleOpen = (event) => {
    if (isMobile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAll2 = (event, resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (
        filterObj.some(
          (filter) => filter.type === resp.type && filter.id === resp.id
        )
      ) {
        return [...filterObj.filter((filter) => filter.id !== resp.id)];
      }
      return [...filterObj, resp];
    });
  };

  // fetches details of a store
  const fetchStoreDetails = async (storeId) => {
    try {
      const response = await getIndiStore(storeId);
      const payload = response.data;
      if (payload.status === "success") {
        setStoreDetails(payload.data?.store ?? {});
      }
    } catch (err) {
      if (err.request.status === 404) {
        nav('/store-finder');
      }
    }
  };

  const fetchRatingDetails = async (storeCode) => {
    try {
      const response = await getStoreRatingDetails(storeCode);
      if (response?.data?.status === "success") {
        setReviewDetails(prev => ({ ...prev, ...response.data?.data }))
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (urlParams && urlParams.id) {
      setStoreCode(urlParams.id);
    }
  }, [urlParams]);

  useEffect(() => {
    if (location && location.state?._id) {
      setStoreDetails(location.state);
    }
  }, [location]);

  useEffect(() => {
    if (!location.state?._id && storeCode) {
      fetchStoreDetails(storeCode);
    }
  }, [storeCode]);

  useEffect(() => {
    if (storeDetails?._id) {
      fetchRatingDetails(storeDetails?._id);
    }
  }, [storeDetails]);

  useEffect(() => {
    if (!storeCode) return
    setLoading(true);
    getFiltersForStoreProducts(storeCode).then(res => {
      if (res.status === "success") {
        generateDynamicFilterArray(res.obj);
      }
    }).finally(() => setLoading(false))
  }, [storeCode])

  useEffect(() => {
    setFilterSelected([]);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!storeCode) return;
    if (!storeDetails) return;
    const params = new URLSearchParams(location.search);
    if (params.get("search") === "") return;
    if (sorting === "descending") {
      params.append("sort", "descending");
    }
    if (sorting === "ascending") {
      params.append("sort", "ascending");
    }
    params.append("limit", productsPerPage);
    params.append("page", currentPage);
    filterSelected?.length > 0 &&
      filterSelected.forEach((value) => {
        if (value.type === "price.mop") {
          params.append(`${value.type}`, value.searchQ);
        }
        if (value.type === "brand") {
          params.append("brand", value.data);
        }
        if (value.type === "category") {
          params.append("hierarchyL2", value.data);
        }
      });
    getSearchedProductForStore(storeCode, params).then((res) => {
      if (res.status === "success") {
        const products = res.products;
        setSearchedProduct({
          loaded: true,
          products,
          no_of_products: res.no_of_products,
        });
        setTotalProducts(res.total_products);
      }
    }).finally(() => setLoading(false))
  }, [filterSelected, setSearchedProduct, currentPage, location, sorting, storeCode, storeDetails]);

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    scrollToProductCard()
  };

  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event, sortValue) => {
    if (sortValue) {
      setSorting(sortValue);
      setCurrentPage(1);
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const headingName = useMemo(() => {
    if (location.pathname?.split("/")[1] === "store") return t("ProductFilters")
    else return t("SearchResult")
  }, [location]);


  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.error(err);
      setHomePageSections(res.sections);
    });
  }, []);

  const generateDynamicFilterArray = useCallback((objValues) => {
    if (!objValues) return
    setFilterListData(() => {
      const arr2 = Object.entries(objValues).map(entries => {
        if (entries[0].toString() === "price") return {
          filter_heading: entries[0].toString(),
          filter_data: entries[1].map((e, index) => {
            if (index !== entries[1].length - 1)
              return {
                type: "price.mop",
                data: e.data,
                searchQ: e.searchQ,
                id: `${entries[0]}-${e.data}-${index}`,
              }
            else return null
          }
          )
        }
        else return {
          filter_heading: entries[0].toString(),
          filter_data: entries[1].map((e, index) => ({
            type: `${entries[0]}`,
            data: e,
            searchQ: e,
            id: `${entries[0]}-${e}-${index}`,
          }))
        }
      });
      return arr2
    })
    const { lowest, highest } = objValues.price[objValues.price.length - 1] ?? { lowest: 0, highest: 0 }
    setMinMaxPrice(prev => ({ ...prev, lowest, highest }));
    setStep(() => Math.ceil((highest - lowest) / 100));
    setPriceRange(prev => [lowest, highest])
  }, []);


  // Main renderer
  return (
    <div className="homepage_wrapper">
      <Section1 id={"homepage_hero"} productData={allProducts} />
      <RetailBanner />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "10px",
          padding: 0,
        }}
      >
        <img
          style={{
            width: useScreenSize() === "desktop" ? "100%" : "100%",
            height: useScreenSize() === "desktop" ? "400px" : "150px",
            objectFit: "cover",
            borderRadius: 0,
          }}
          src={RightBanner}
          alt=""
        />
      </div>
      <>

        <div>
          <div
            className={`main_Content_Show ${toggleFilter && !matches ? "main_Content_Hide" : ""
              }`}
          >
            {loading ?
              <div className="loader-div"><CircularProgress color="inherit" /></div>
              : <>
                <div className="d-flex flex-column justify-content-around align-items-center my-1">
                  <h3 className="mx-3 mb-0">{storeDetails?.fc_name}</h3>
                  {userContext?.id && <div className="me-2">
                    <div style={{
                      fontSize: "0.8rem",
                    }}
                      onClick={() => setOpenReviewModal(true)}
                      className="review_btn mt-2"
                    >
                      {t("ShareYourInStoreExperience")}
                    </div>
                  </div>}
                  <div
                    className={`d-flex ${isMobile
                      ? "flex-column"
                      : "flex-row"
                      } justify-content-evenly gap-3 align-items-center`}
                    style={{cursor: "pointer"}}
                    onClick={() => document.querySelector(".reviewHead").scrollIntoView()}
                  >
                    {reviewDetails?.ratingAvg >= 4 && <div className="btn-best-seller">
                      <button className="btn btn-primary btn-res-style border-0">
                        {t("product-page.best-seller")}
                      </button>
                    </div>}
                    <Rating value={Math.ceil(reviewDetails?.ratingAvg)} readOnly />
                    <p className="my-2"
                      style={{
                        fontSize: !isMobile
                          ? "10px"
                          : "13px",
                        fontWeight: "700",
                        lineHeight: "19px",
                        display: isMobile
                          ? "inline-block"
                          : "",
                      }}
                    >
                      <span style={{ color: "#7F7F7F" }}>
                        {
                          reviewDetails.totalReviews > 0 ?
                            `${reviewDetails.totalReviews} Reviews` :
                            `${reviewDetails.totalReviews} Review`} | {reviewDetails.totalSolds} Sold
                      </span>
                    </p>
                  </div>
                </div>
                <div className="desk_Page_Wrapper_res">
                  {isMobile ? (
                    <div>
                      <Drawer
                        varaint="temporary"
                        anchor="right"
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{ style: { zIndex: 1200 } }}
                      >
                        <DrawerSection
                          isMobile={isMobile}
                          onClick={handleClose}
                          value={priceRange}
                          handleChange={handleChange}
                          filterListData={filterListData}
                          handleAll2={handleAll2}
                          filterSelected={filterSelected}
                          handlePriceRangeSubmit={handlePriceRangeSubmit}
                          minMaxPrice={minMaxPrice}
                          sliderStep={step}
                          handlePriceReset={handlePriceReset}
                          headingName={headingName}
                          priceRange={priceRange}
                        />
                      </Drawer>
                    </div>
                  ) : (
                    <DrawerSection
                      filterListData={filterListData}
                      isMobile={isMobile}
                      value={priceRange}
                      handleChange={handleChange}
                      handleAll2={handleAll2}
                      filterSelected={filterSelected}
                      handlePriceRangeSubmit={handlePriceRangeSubmit}
                      minMaxPrice={minMaxPrice}
                      sliderStep={step}
                      handlePriceReset={handlePriceReset}
                      headingName={headingName}
                      priceRange={priceRange}
                    />
                  )}

                  <div className="order_Page_Right_res">
                    {searchedProduct.no_of_products > 0 ? (
                      <>
                        <div className="right-wrapper">
                          <div className="SpotLight_res">
                            <div>
                              <span>{t('Spotlight')}</span>
                            </div>
                            <div className="SpotLight_res-items" onClick={handleOpen}>
                              {isMobile ? (
                                <FilterAltOutlinedIcon />
                              ) : (
                                <SegmentIcon fontSize="large" />
                              )}
                              {isMobile ? (
                                <span>{t('Filter')}</span>
                              ) : (
                                <span
                                  ref={anchorRef}
                                  id="composition-button"
                                  aria-controls={
                                    open ? "composition-menu" : undefined
                                  }
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("sortAndFilter.sort-by")}
                                </span>
                              )}
                              <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                                style={{ zIndex: "100000" }}
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom-start"
                                          ? "left top"
                                          : "left bottom",
                                    }}
                                  >
                                    <Paper >
                                      <ClickAwayListener
                                        onClickAway={handleClosePOP}
                                      >
                                        <MenuList
                                          autoFocusItem={open}
                                          id="composition-menu"
                                          aria-labelledby="composition-button"
                                          onKeyDown={handleListKeyDown}
                                        >
                                          <MenuItem onClick={(event) => handleClosePOP(event, "")}>
                                            <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "" && true} /> <span>{t("default")}</span>
                                          </MenuItem>
                                          <MenuItem onClick={(event) => handleClosePOP(event, "ascending")}>
                                            <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "ascending" && true} /> <span>{t("sortAndFilter.price-ascending")}</span>
                                          </MenuItem>
                                          <MenuItem onClick={(event) => handleClosePOP(event, "descending")}>
                                            <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "descending" && true} /> <span>{t("sortAndFilter.price-descending")}</span>
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </div>
                          </div>
                          <div className={"Product_Category_Container_res"}>
                            {searchedProduct.products.map((product, index) => (

                              <ProductCard
                                key={`product-${index}`}
                                id={product._id}
                                productImg={product.images[0]}
                                productName={product.title}
                                productDescription={product?.description[0]?.split(" ")?.slice(0, 18)?.join(" ")}
                                productRating={product?.rating ?? 0}
                                productPriceObj={product.price}
                                productDiscountObj={product.discount}
                                productSlug={product.slug}
                                wishlist={userWishlist.wishlist_items?.some(obj => obj._id?.toString() === product._id?.toString())}
                                cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some(obj => obj._id?.toString() === product._id?.toString())}
                              />
                            ))}
                          </div>
                          <div className="pagination-con">
                            <Pagination
                              sx={{
                                color: "primary.main",
                              }}
                              page={currentPage}
                              defaultPage={1}
                              onChange={handlePageChange}
                              count={Math.ceil(totalProducts / productsPerPage)}
                              variant="outlined"
                              shape="rounded"
                              size="large"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="empty_order_sec_res">
                        <p className="empty_order_text_res">
                          {t("sortAndFilter.no-product")}
                        </p>
                        <button
                          type="submit"
                          className="submit-button"
                          onClick={() => nav("/")}
                        >
                          <p>{t("back-to-home")}</p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </>
      <GetinTouchPage store={storeDetails} />
      <div className="googleMapsContainer">
        <div className="googleMapsContainerHeader">
          <h1 className="googleMapsDirectionHeading">Directions</h1>
          <img alt="northIcon" src={northIcon} className="northIcon" />
        </div>
        <div id="googleMapsDiv">
          <div
            style={{ width: { width }, height: "500px", borderRadius: "10px", overflow: "hidden" }}
          >
            <MapWrapper center={userLocation.coordinates} markerPositions={[{
              lat: +storeDetails.latitude, lng: +storeDetails.longitude
            }]} />
          </div>
        </div>
      </div>
      {storeDetails?._id && <div className="p-3 p-lg-5">
        <StoreReview storeId={storeDetails?._id} />
      </div>}
      {storeDetails?._id && <StoreReviewModal
        open={openReviewModal}
        handleClose={() => setOpenReviewModal(false)}
        setOpenModal={setOpenReviewModal}
        isMobile={isMobile}
        storeId={storeDetails?._id}
      />}
    </div>
  );
};

export default RetailStorePage;
