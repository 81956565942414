import { useEffect, useState } from "react";
import "./PriceDetailsBox.css";
import useUserCtx from "../../hooks/useUserCtx";
import { getSearchedProduct } from "../../api/Product";

// Utils
import { formatNumber, isOfferActive } from "../../utils";
import { useTranslation } from "react-i18next";

// Component definition
const PriceDetailsBox = ({ HideDetails, classes }) => {
    const [showDetails, setShowDetails] = useState(true);
    const [comboProducts, setComboProducts] = useState([]);
    const { t } = useTranslation();
    const {
        appliedCoupon,
        priceBoxDetails,
        cartArray
    } = useUserCtx();

    useEffect(() => {
        cartArray.cart.forEach((prod) => {
            // check the product has combo offer and if yes fetch that product
            const comboOffer = prod.discount?.combo;
            if (comboOffer?.value && isOfferActive(comboOffer)) {
                fetchComboProduct(comboOffer.value);
            }

            // cleanup on componentWillUnmount
            return () => {
                setComboProducts([]);
            }
        })
    }, [cartArray])

    /** Handler functions - starts */
    const fetchComboProduct = async (productEan) => {
        const response = await getSearchedProduct(`ean=${productEan}`);
        const product = response.products[0];
        setComboProducts(prev => {
            return [...prev, { ...product, isComboProduct: true }];
        })

    }
    /** Handler functions - ends */

    // Main renderer
    return (
        <div className={"cart_Price_details section_Wrapper " + (classes ? classes.containerClass : "")}>
            <div className="cart_Details_Header">
                <p>{t('cart.price-details')}</p>
            </div>
            {showDetails && (
                <div className="cart_Details_Body">
                    <div className="cart_Details_Price">
                        <p>{t('sortAndFilter.price')} ({priceBoxDetails.cartItemsNumber} {t('cart.items')}) </p>
                        <p>₹{formatNumber(priceBoxDetails.cartItemsPrice)}</p>
                    </div>
                    <div className="cart_Details_Discount">
                        <p>{t('sortAndFilter.discount')}</p>
                        <p>-₹{formatNumber(priceBoxDetails.totalDiscount)}</p>
                    </div>
                    <div className="cart_Details_Delivery">
                        <p>{t('cart.delivery-charges')}</p>
                        <p>₹{formatNumber(priceBoxDetails.totalDeliveryCharge)}</p>
                    </div>
                    <div className="cart_Details_Delivery">
                        <p>{t('cart.coupon')}</p>
                        <p>{appliedCoupon?.code || t('cart.none')}</p>
                    </div>
                    <div className="cart_Details_Delivery">
                        <p>{t('cart.combo')}</p>
                        {comboProducts.length > 0 ? (
                            <p>{comboProducts.length}</p>
                        ): (
                            <p>{t('cart.none')}</p>
                        )}
                    </div>
                </div>
            )}
            <div className="cart_Details_Footer">
                <p>{t('cart.total-amt')}</p>
                <p>₹{formatNumber(priceBoxDetails.totalAmount)}</p>
            </div>
            {HideDetails && (
                <div className="cart_Details_Hide" onClick={() => setShowDetails(!showDetails)}>
                    <p>{showDetails ? "Hide" : "Show"} {t('cart.price-details')}</p>
                </div>
            )}
        </div>
    );
};

export default PriceDetailsBox;
