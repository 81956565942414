import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import Image from "../../assets/png/changeTheNameImage.png";
import { useMediaQuery } from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useLocation, useNavigate } from "react-router-dom";
import useUserCtx from "../../hooks/useUserCtx";
import { toast } from "react-toastify";
import { getUser, sendOtpUpdatePhone, updateUser, verifyOtpUpdatePhone } from "../../api/Auth";

const ChangeTheName = () => {
  const isMobile = useMediaQuery("(max-width: 500px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [fieldName, setFieldName] = useState({ field: "", value: "" });
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const { setUserContext } = useUserCtx();
  const [phoneOTP, setPhoneOTP] = useState("");
  const [timer, setTimer] = useState(0);
  const otpRef = useRef();
  const [isSend, setIsSend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setTimeout(() => {
        setTimer(prev => prev - 1)
      }, 1000)
    } else {
      setTimer(0);
    }
    return () => {
      clearInterval(interval)
    }
  }, [timer])

  useEffect(() => {
    if (location.state?.type !== "edit-details" || location.state?.field === "") {
      navigate("/loginAndSecurity");
    }
    else setFieldName({ value: location.state.value, field: location.state.field });
  }, []);

  function handleInputChange(event) {
    setFieldName(prev => ({ ...prev, value: event.target.value }))
  }

  function handleCheckboxChange(e) {
    setChecked(prev => !prev);
  }

  function handleCancel() {
    navigate("/loginAndSecurity");
  }

  function handleSubmit() {
    const data = {
      [fieldName.field]: fieldName.value
    };
    if (location.state.value?.toString()?.trim() === fieldName.value?.toString()?.trim()) toast.error("Please make some changes")
    else updateUser(data).then((res) => {
      if (res.status === "success") {
        toast.success("Details Updated Successfully");
        getUser().then((res) => {
          if (res) {
            let user = res;
            setUserContext((prev) => ({
              ...prev,
              ...user
            }));
          }
        });
        navigate("/loginAndSecurity");
      }
    })
  }

  async function sendOTPForMobile() {
    try {
      const data = {
        mobileNumber: fieldName.value
      };
      const res = await sendOtpUpdatePhone(data);
      //Success  
      if (res?.status === 201) {
        toast.success(res?.data?.message);
        setTimer(60);
        otpRef.current?.classList.add("expandable-show");
        setIsSend(true)
      } else {
        //Error
        toast.error(res?.response?.data?.message ?? "Something went wrong");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
      console.log(err)
    }
  }

  async function handleOTPVerify() {
    try {
      const data = {
        otp: phoneOTP,
        mobileNumber: fieldName.value
      }
      const res = await verifyOtpUpdatePhone(data);
      //Success  
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        getUser().then((res) => {
          if (res) {
            let user = res;
            setUserContext((prev) => ({
              ...prev,
              ...user
            }));
          }
        });
        setIsSend(false);
        navigate("/loginAndSecurity");
      } else {
        //Error
        toast.error(res?.response?.data?.message ?? "Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    setDisabled(!checked)
  }, [checked]);

  return (
    <div className="changeNameContainer">
      <div className="changeNameContent">
        <div className="changeNameFormContainer">
          <h1 className="changeNameHeading breadcrumbs">Profile / Login & Security</h1>
          {fieldName?.field === "fullName" &&
            <div className="changeNameForm">
              <h1 className="changeNameFormLabelTitle">Name:</h1>
              <div className="changeNameInputDiv">
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  className="changeNameInputField"
                  value={fieldName.value}
                  onChange={handleInputChange}
                />
              </div>
              <div className="changeNamecheckBoxDiv">
                <input type="checkbox" className="changeNameCheckbox" checked={checked} onChange={handleCheckboxChange} />
                <span className="changeNameConfirmCheckbox">
                  Confirm the Change
                </span>
              </div>

              <div className="changeNameButtonSection">
                <button
                  type="submit"
                  className="changeNamesubmitButton"
                  style={{
                    background: "#1B325E",
                    maxWidth: "290px",
                    minWidth: isMobile ? "130px" : "240px",
                  }}
                  disabled={disabled}
                  onClick={handleSubmit}
                >
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    Save Changes
                  </p>
                </button>
                <button className="changeNamecancelButton" onClick={handleCancel}>
                  <CancelPresentationIcon
                    style={{
                      fontSize: "24px",
                      fontWeight: "200",
                    }}
                  />{" "}
                  &nbsp;
                  <span className="changeNamecartButtons1Cancel">
                    Cancel
                  </span>
                </button>
              </div>
            </div>}
          {fieldName?.field === "mobileNumber" &&
            <div className="changeNameForm">
              <h1 className="changeNameFormLabelTitle">Mobile phone number:</h1>
              <div className="changeNameInputDiv">
                <input
                  type="tel"
                  inputMode="tel"
                  name="phone"
                  placeholder="Phone Number"
                  className="changeNameInputField"
                  value={fieldName.value}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>

              <div className="changeNamecheckBoxDiv" style={{
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <button
                  className="changeNamesubmitButton"
                  style={{
                    background: "#1B325E",
                    maxWidth: "137px",
                  }}
                  disabled={parseInt(timer) !== 0}
                  onClick={sendOTPForMobile}
                >
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                   {isSend ?  "Resend OTP" :  "Send OTP"}
                  </p>
                </button>
               { timer !== 0 && <span style={{
                fontSize: "10px",
                color: "rgba(0, 0, 0, 0.7)",
                marginRight: "7rem"
               }}> Resend OTP in <b style={{
                fontSize: "13px"
               }}>{timer}</b> seconds</span>
               }
              </div>

              <div className="changeNameInputDiv expandable-hide" ref={otpRef}>
                <input
                  value={phoneOTP}
                  onChange={e => setPhoneOTP(e.target.value)}
                  type="tel"
                  inputMode="tel"
                  maxLength="6"
                  minLength="6"
                  placeholder="Enter OTP"
                  className="changeNameInputField"
                />
              </div>

              <div className="changeNameButtonSection">
                <button
                  type="submit"
                  className="changeNamesubmitButton"
                  style={{
                    background: "#1B325E",
                    maxWidth: "290px",
                    minWidth: isMobile ? "130px" : "240px",
                  }}
                  disabled={phoneOTP?.toString().length !== 6}
                  onClick={handleOTPVerify}
                >
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    Confirm
                  </p>
                </button>
                <button className="changeNamecancelButton" onClick={handleCancel}>
                  <CancelPresentationIcon
                    style={{
                      fontSize: "24px",
                      fontWeight: "200",
                    }}
                  />{" "}
                  &nbsp;
                  <span className="changeNamecartButtons1Cancel">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          }
          {fieldName?.field === "email" &&
            <div className="changeNameForm">
              <h1 className="changeNameFormLabelTitle">E-mail:</h1>
              <div className="changeNameInputDiv">
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="changeNameInputField"
                  value={fieldName.value}
                  onChange={handleInputChange}
                />
              </div>
              <div className="changeNamecheckBoxDiv">
                <input type="checkbox" className="changeNameCheckbox" checked={checked} onChange={handleCheckboxChange} />
                <span className="changeNameConfirmCheckbox">
                  Confirm the Change
                </span>
              </div>

              <div className="changeNameButtonSection">
                <button
                  type="submit"
                  className="changeNamesubmitButton"
                  style={{
                    background: "#1B325E",
                    maxWidth: "290px",
                    minWidth: isMobile ? "130px" : "240px",
                  }}
                  disabled={disabled}
                  onClick={handleSubmit}
                >
                  <p
                    style={{
                      color: "white",
                    }}
                  >
                    Save Changes
                  </p>
                </button>
                <button className="changeNamecancelButton" onClick={handleCancel}>
                  <CancelPresentationIcon
                    style={{
                      fontSize: "24px",
                      fontWeight: "200",
                    }}
                  />{" "}
                  &nbsp;
                  <span className="changeNamecartButtons1Cancel">
                    Cancel
                  </span>
                </button>
              </div>
            </div>}
          {/* <div className="changeNameForm">
            <h1 className="changeNameFormLabelTitle">Password:</h1>
            <h3 className="changeNameFormLabelSubTitle">New Password</h3>
            <div className="changeNameInputDiv">
              <input
                type="text"
                name="password"
                placeholder="At least 6 characters"
                className="changeNameInputField"
              />
            </div>
            <p
              className="changePassNote"
              style={{ marginLeft: isMobile ? "20px" : "40px", marginBottom: "15px" }}
            >
              <img src={InfoIcon} alt="infoIcon" width="6px" height="14px" />{" "}
              Passwords must be at least 6 characters.
            </p>
            <h3 className="changeNameFormLabelSubTitle">Password again</h3>
            <div className="changeNameInputDiv">
              <input
                type="text"
                name="passwordAgain"
                className="changeNameInputField"
              />
            </div>
            <div className="changeNamecheckBoxDiv">
              <input type="checkbox" className="changeNameCheckbox" />
              <span className="changeNameConfirmCheckbox">
                Confirm the Change
              </span>
            </div>

            <div className="changeNameButtonSection">
              <button
                type="submit"
                className="changeNamesubmitButton"
                style={{
                  background: "#1B325E",
                  maxWidth: "290px",
                  minWidth: isMobile ? "130px" : "240px",
                }}
              >
                <p
                  style={{
                    color: "white",
                  }}
                >
                  Continue
                </p>
              </button>
              <button className="changeNamecancelButton">
                <CancelPresentationIcon
                  style={{
                    fontSize: "24px",
                    fontWeight: "200",
                  }}
                />{" "}
                &nbsp;
                <span className="changeNamecartButtons1Cancel">
                  Cancel
                </span>
              </button>
            </div>
          </div> */}
        </div>
        <div className="changeNameImageContainer">
          <img src={Image} alt="changeTheNameImageBackground" />
        </div>
      </div>
    </div>
  );
};

export default ChangeTheName;
