import { Skeleton } from '@mui/material';
import React from 'react';
import "./styles.css"

const ProductBoxSkeleton = (props) => {
    return (
        <div className="product-box-skeleton d-flex flex-column gap-2" style={{
            height: props?.height ?? "auto"
        }}>
            <Skeleton variant="rectangular" animation="wave" className="skeleton-item" height={"10%"} />
            <Skeleton variant="rectangular" animation="wave" className="skeleton-item" height={"50%"} />
            <Skeleton variant="rectangular" animation="wave" className="skeleton-item" height={"20%"} />
            <div className="d-flex justify-content-between gap-2 align-items-center">
                <Skeleton variant="rectangular" animation="wave" className="skeleton-item-child-1" height={"100%"} />
                <Skeleton variant="circular" animation="wave" width={40} height={40} />
            </div>
        </div>
    );
}

export default ProductBoxSkeleton;
