import React from "react";
import { Modal } from "react-bootstrap";

//CSS
import "./Modal.css";

//Images

const MultiOfferModal = ({ modalShow, setModalShow, allProducts }) => {
  const handleClose = () => setModalShow(false);
  return (
    <>
      <Modal show={modalShow} onHide={handleClose} backdrop="true" keyboard={false} backdropClassName="backdrop_active">
        <Modal.Header closeButton>
          <h5>Add Offers to Multiple Products</h5>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default MultiOfferModal;
