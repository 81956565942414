import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Rating, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addNewStoreComment } from "../../api/AdminApis/AdminStore";

function StoreReviewModal({ open, handleClose, setOpenModal, storeId }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [storeRating, setStoreRating] = useState(0);
    const [reviewData, setReviewData] = useState({
        name: '',
        email: '',
        storeReview: '',
        hasVisited: 'no'
    });
    const { userContext } = useUserCtx();

    const onInputChange = (event) => {
        const { name, value } = event.target;
        setReviewData({ ...reviewData, [name]: value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                review: reviewData.storeReview,
                userId: userContext.id,
                rating: storeRating,
                storeId: storeId,
                hasVisited: reviewData.hasVisited === "yes" ? true : false,
            }
            const response = await addNewStoreComment(payload);
            if (response.data.status === "success") {
                toast.success(t("notifications.CommentForStoreAdded"));
                setReviewData({ ...reviewData, reviewText: '' });
                setStoreRating(0);
                setOpenModal(false)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        if (userContext && userContext?.id) {
            setReviewData({
                ...reviewData,
                name: userContext.fullName,
                email: userContext.email
            })
        }
    }, [userContext]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'body'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                zIndex: 100000
            }}
        >
            <div className="section_Wrapper" style={{ padding: "20px 30px", background: "#F2F2F2", display: "flex", flexDirection: "column" }}>
                <DialogTitle id="scroll-dialog-title">{t("AddAReview")}</DialogTitle>
                <DialogContent>
                    {(userContext && userContext?.id) ? 
                    <>
                        <p className='reviewSubHead mb-3'>Your Email Address Will Not Be Published. Required Fields Are Marked *</p>
                        <Box component="form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "15px" }}>
                            <TextField
                                required
                                id="name-input"
                                label={t("login.name")}
                                name="name"
                                inputProps={{ readOnly: true }}
                                value={reviewData.name}
                                style={{ background: "white" }}
                                onChange={onInputChange}
                            />
                            <TextField
                                required
                                id="email-input"
                                label={t("login.email")}
                                type="email"
                                name="email"
                                inputProps={{ readOnly: true }}
                                style={{ background: "white" }}
                                value={reviewData.email}
                                onChange={onInputChange}
                            />
                            <FormControl>
                                <label>{t("HaveYouVisitedThisStore")}</label>
                                <RadioGroup row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={reviewData.hasVisited}
                                    onChange={onInputChange}
                                >
                                    <FormControlLabel name="hasVisited" value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel name="hasVisited" value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            <Rating
                                precision={0.5}
                                name="store-rating"
                                value={storeRating}
                                onChange={(event, newValue) => {
                                    setStoreRating(newValue);
                                }}
                            />
                            <TextField
                                required
                                id="store-review-input"
                                label={t("StoreReview")}
                                multiline
                                rows={4}
                                name="storeReview"
                                style={{ background: "white" }}
                                value={reviewData.storeReview}
                                onChange={onInputChange}
                            />

                            <div className="submit_Button_3" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <button
                                    type="submit"
                                    className="submit-button"
                                    style={{ background: "#1B325E", width: "40%" }}
                                >
                                    <p style={{ color: "white" }}>{t("addReview")}</p>
                                </button>
                                <div style={{ width: "70%" }}></div>
                            </div>
                        </Box>
                    </> 
                    : 
                    <div className='mt-3 text-center'>
                        {t("LoginToAddReviews")}
                        <button
                            className="submit-button py-2 px-2 h-auto w-75 mt-3"
                            style={{ background: "#1B325E" }}
                            onClick={() => navigate('/login')}
                        >
                            <p style={{ color: "white" }}>{t("Login")}</p>
                        </button>
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button className="header_sign_in_btn" onClick={handleClose}>{t("cancel")}</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default StoreReviewModal;
