import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getOfferImageURLAPI } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";
import Stage from "../../assets/IFD/Stage.png";
import StageCircle from "../../assets/IFD/Stage_Circle.png";

const audioUrl = "https://olineoindia.com/Offers/IFD_Sound.mp3";

const LEFT_STYLE = {
    1: "8px",
    2: "-4px",
    3: "-4px",
    4: "-10px",
    5: "10px",
    6: "0px",
    7: "6px",
    8: "-10px",
    9: "-12px",
    10: "1px"
};

function OfferReveal() {
    const {
        OfferId,
        getOfferDetails
    } = useContext(
        OfferContext
    );
    const navigate = useNavigate();

    const [receivedPrize, setReceivedPrize] = useState('');
    const [prizeIndex, setPrizeIndex] = useState('');
    let audio = new Audio(audioUrl);

    useEffect(() => {
        (async () => {
            try {
                const data = await getOfferImageURLAPI(OfferId);
                if (data?.status === "error") {
                    toast.error(data?.message ?? "Something went wrong");
                    navigate("/Republic-Week-Offer");
                }
                else {
                    if (data?.imageURL) {
                        const offerURL = data.imageURL;
                        setReceivedPrize(offerURL);
                        setPrizeIndex(data.prizeIndex);
                        playAudioInLoop();
                    }
                    else toast.error("Something went wrong");
                }
            } catch (error) {
                toast.error("Something went wrong");
            }
        })();

        const offerDetails = getOfferDetails();

        const expectedKeys = ["userId", "offerId", "offerLink"];

        const hasExpectedKeys = Object.keys(offerDetails).every(key => expectedKeys.includes(key)) &&
            expectedKeys.every(key => offerDetails.hasOwnProperty(key));

        if (!hasExpectedKeys) {
            toast.error("Something went wrong");
            navigate("/Republic-Week-Offer");
        } else {
            const offerURL = offerDetails.offerLink;
            if (!offerURL) {
                toast.error("Something went wrong");
                navigate("/Republic-Week-Offer");
            }
            setReceivedPrize(offerURL);
            playAudioInLoop();
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.removeEventListener('ended', playAudioInLoop);
            }
        };
    }, []);

    const playAudioInLoop = () => {
        audio.volume = 0.8;

        const playAudio = () => {
            audio.currentTime = 0;
            audio.play();
        };

        audio.addEventListener('ended', playAudio);
        audio.play();
    };

    return (
        <>
            <div className="container sub-container text-center">
                <div className="d-flex justify-content-center offer-reveal-wrapper" style={{
                    width: "375px",
                    margin: "auto",
                }}>
                    <img src={receivedPrize} alt="stage_circle" className="prize-img relative" style={{
                        left: LEFT_STYLE[prizeIndex?.toString()] 
                    }} />
                    <img src={StageCircle} alt="stage_circle" className="stage-circle" />
                    <img src={Stage} alt="stage" className="stage-img" />
                </div>

                <button
                    onClick={() => navigate('/Republic-Week-Offer/6')}
                    type="button"
                    className="btn btn-warning mt-3 mb-5 fw-bolder relative page_3_btn"
                    id="submit-otp-btn"
                    style={{
                        width: "70%",
                        background: "var(--primaryYellow)",
                        color: "black"
                    }}
                >
                    <span>Follow Us</span>
                </button>
            </div>
        </>
    );
}



export default OfferReveal;